// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myDiagramDiv {
  background: whitesmoke;
  width: 80%;
  height: 400px;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNyZWF0ZS1wcm9qZWN0LWNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxzQkFBc0I7RUFDdEIsVUFBVTtFQUNWLGFBQWE7QUFDZiIsImZpbGUiOiJjcmVhdGUtcHJvamVjdC1jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm15RGlhZ3JhbURpdiB7XG4gIGJhY2tncm91bmQ6IHdoaXRlc21va2U7XG4gIHdpZHRoOiA4MCU7XG4gIGhlaWdodDogNDAwcHg7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/containers/ticketing/components/projects/create-project-component/create-project-component.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,UAAU;EACV,aAAa;AACf;;AAEA,oZAAoZ","sourcesContent":[".myDiagramDiv {\n  background: whitesmoke;\n  width: 80%;\n  height: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
