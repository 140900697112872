import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';

import {PurchasingOrderService} from '@appServices/core-inventory/purchasing-order/purchasing-order.service';
import {ToastrService} from 'ngx-toastr';
import {
  PurchasingOrderCreate,
  PurchasingOrderActionTypes,
  PurchasingOrderDoNothing,
  PurchasingOrderError,
  PurchasingOrderOk
} from '../../../actions/core-inventory/purchasing-order/purchasing-order.action';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as fromReducer from '../../../reducers';

@Injectable()
export class PurchasingOrderEffects {

  createPurchasingOrder = createEffect(() => this.actions$.pipe(
    ofType(PurchasingOrderActionTypes.purchasingOrderCreate),
    switchMap((action: PurchasingOrderCreate) =>
      this.purchasingOrderService
        .savePurchasingOrder(action.payload)
        .pipe(
          map(
            purchasingOrder => {
             console.log('purchasing order', purchasingOrder);
              this.toastr.success('Purchasing order created successfully!', 'Success');
              this.router.navigate([`inventory/purchasing-order`]);
              return new PurchasingOrderOk();
            }
          ),
          catchError(error => of(new PurchasingOrderError(error)))
        )
    )
  ));





  purchasingOrderDoNothing$ = createEffect(() => this.actions$.pipe(
    ofType(PurchasingOrderActionTypes.purchasingOrderDoNothing),
    tap(() => this.router.navigate([`/inventory/purchasing-order`]))
  ), {dispatch: false});


  purchasingOrderOk$ = createEffect(() => this.actions$.pipe(
    ofType(PurchasingOrderActionTypes.purchasingOrderOk)
  ), {dispatch: false});






  constructor(
    private actions$: Actions,
    private purchasingOrderService: PurchasingOrderService,
    private toastr: ToastrService,
    private router: Router,
    private store: Store<fromReducer.consignmentProduct.State>,
  ) {
  }


}



