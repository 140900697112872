import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import { Route } from '@appModels/core-fieldforce/route/route';

import { CoreFieldforceService } from '../core-fieldforce.service';


@Injectable({ providedIn: 'root' })
export class RouteService extends CoreFieldforceService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'route');
  }

  /** GET routes from the server */
  // getRoutes(query?): Observable<Route[]> {
  //   let params = new HttpParams();
  //   if (query !== undefined){
  //     // {page:1, size:10, sort: '' }
  //     Object.keys(query).forEach(val => {
  //       params = params.append(val, query[val]);
  //     });
  //   }
  //   return this.http.get<any>(this.base_url + '/', { headers: this.headers }).pipe(
  //     tap(routes => this.log(`fetched routes`)),
  //     catchError(this.handleError('getRoutes', []))
  //   );
  // }

  getRoutes(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + '/tenant/' + localStorage.getItem('tenant'), {
      params,
      headers: this.headers
    }).pipe(
      tap(routes => this.log(`fetched routes`)),
      catchError(this.handleError('getRoutes', []))
    );
  }

  /** GET route by id. Return `undefined` when id not found */
  getRouteNo404<Data>(id: number): Observable<Route> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<Route[]>(url).pipe(
      map(routes => routes[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} route id=${id}`);
      }),
      catchError(this.handleError<Route>(`getRoute id=${id}`))
    );
  }

  /** GET route by id. Will 404 if id not found */
  getRoute(id: number): Observable<Route> {
    const url = `${this.base_url}/${id}/`;
    return this.http.get<Route>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched route id=${id}`)),
      catchError(this.handleError<Route>(`getRoute id=${id}`))
    );
  }

  /* GET routes whose name contains search term */
  searchRoutes(term: string): Observable<Route[]> {
    if (!term.trim()) {
      // if not search term, return empty route array.
      return of([]);
    }
    return this.http.get<Route[]>(`api/routes/?name=${term}`).pipe(
      tap(_ => this.log(`found routes matching "${term}"`)),
      catchError(this.handleError<Route[]>('searchRoutes', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new route to the server */
  addRoute(route: Route): Observable<Route> {
    return this.http.post<Route>(this.base_url + '/', route, { headers: this.headers }).pipe(
      tap((route: Route) => this.log(`added route w/ id=${route.id}`)),
      catchError(this.handleError<Route>('addRoute'))
    );
  }

  /** DELETE: delete the route from the server */
  deleteRoute(route: Route | number): Observable<Route> {
    const id = typeof route === 'number' ? route : route.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<Route>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted route id=${id}`)),
      catchError(this.handleError<Route>('deleteRoute'))
    );
  }

  /** PUT: update the route on the server */
  updateRoute(route: Route): Observable<any> {
    return this.http.put(`${this.base_url}/${route.id}`, route, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated route id=${route.id}`)),
      catchError(this.handleError<any>('updateRoute'))
    );
  }

  /** Patch: update the route on the server */
  editRoute(data: any): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`edit route id=${data.id}`)),
      catchError(this.handleError<any>('editRoute'))
    );
  }

}
