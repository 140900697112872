// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --configuration=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  //base_url: 'https://coregateway.dev.dlight.com',
  base_url: 'https://coregateway.gke.staging.dlight.com',
  // base_url: 'http://localhost:10115',
  // base_url: 'http://localhost:10011/',
  graph_url: 'https://graphql.gke.staging.dlight.com/graphql',
  api_ver: 'api/v1',
  version: 'DEV',
  name: 'local',
  sentry_enabled: false,
  flexmonster_license: 'Z7Y0-10D36B-3E516H-5K1Y0Z-6S0Z4L-4P3B2L-2T663Y-0O721Z-1S1O52-613K2R-0Q5H3O',
  base_web_url: 'https://atlas.gke.staging.dlight.com',
  maktaba_user:'',
  maktaba_password:''
};
