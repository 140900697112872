import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';


import {CoreKnowledgeService} from '../core-knowledge.service';
import { Page } from '@appModels/core-knowledge-base/pages/page';


@Injectable({providedIn: 'root'})
export class KnowledgeBaseSpaceService extends CoreKnowledgeService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'knowledgebasespaces');
  }

  /** GET knowledgeBaseSpace from the server */
  getKnowledgeBaseSpaces(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + `/tenant/` + localStorage.getItem('tenant'), {
      params,
      headers: this.headers
    }).pipe(
      tap(knowledgeBaseSpace => this.log(`fetched knowledgeBaseSpace`)),
      catchError(this.handleError('getKnowledgeBaseSpace', []))
    );
  }

  /** GET knowledgeBaseSpace by id. Return `undefined` when id not found */
  getKnowledgeBaseSpaceNo404<Data>(id: number): Observable<Page> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<Page[]>(url).pipe(
      map(knowledgeBaseSpace => knowledgeBaseSpace[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} knowledgeBaseSpace id=${id}`);
      }),
      catchError(this.handleError<Page>(`getKnowledgeBaseSpace id=${id}`))
    );
  }

  /** GET knowledgeBaseSpace by id. Will 404 if id not found */
  getKnowledgeBaseSpace(id: number): Observable<any> {
    const url = `${this.base_url}/${id}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched knowledgeBaseSpace id=${id}`)),
      catchError(this.handleError<any>(`getKnowledgeBaseSpace id=${id}`))
    );
  }

  /** GET knowledgeBaseSpace by version id. Will 404 if id not found */
  getKnowledgeBaseSpaceVersions(tenantId: number, id: number): Observable<any> {
    const url = `${this.base_url}/${tenantId}/${id}/versions`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched knowledgeBaseSpace id=${id}`)),
      catchError(this.handleError<any>(`getKnowledgeBaseSpace id=${id}`))
    );
  }

  /** GET knowledgeBaseSpace by tenant id. Will 404 if id not found */
  getKnowledgeBaseSpaceByTenant(): Observable<any> {
    const url = `${this.base_url}/tenant/`+ localStorage.getItem('tenant');
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched knowledgeBaseSpace id=`)),
      catchError(this.handleError<any>(`getKnowledgeBaseSpace id=`))
    );
  }

   /** GET knowledgeBaseSpaceFile by id. Will 404 if id not found */
   getKnowledgeBaseSpaceFiles(fileId: number): Observable<Page> {
    const url = `${this.base_url}/file/${fileId}`;
    return this.http.get<Page>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched knowledgeBaseSpace id=${fileId}`)),
      catchError(this.handleError<Page>(`getKnowledgeBaseSpace id=${fileId}`))
    );
  }

  /* GET knowledgeBaseSpace whose name contains search term */
  searchKnowledgeBaseSpace(term: string): Observable<Page[]> {
    if (!term.trim()) {
      // if not search term, return empty knowledgeBaseSpace array.
      return of([]);
    }
    return this.http.get<Page[]>(`api/knowledgeBaseSpace/?name=${term}`).pipe(
      tap(_ => this.log(`found knowledgeBaseSpace matching "${term}"`)),
      catchError(this.handleError<Page[]>('searchKnowledgeBaseSpace', []))
    );
  }

  //////// Save methods //////////
  /** POST: add a new knowledgeBaseSpace to the server */
  addKnowledgeBaseSpace(knowledgeBaseSpace: any): Observable<any> {
    this.setHeader(true);
    return this.http.post<any>(this.base_url, knowledgeBaseSpace, {headers: this.headers}).pipe(
      tap((tm: any) => this.log(`added knowledgeBaseSpace w/ id=${tm.id}`)),
      catchError(this.handleError<any>('addKnowledgeBaseSpace'))
    );
  }

  /** DELETE: delete the knowledgeBaseSpace from the server */
  deleteKnowledgeBaseSpace(knowledgeBaseSpace: Page | number): Observable<Page> {
    const id = typeof knowledgeBaseSpace === 'number' ? knowledgeBaseSpace : knowledgeBaseSpace.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<Page>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted knowledgeBaseSpace id=${id}`)),
      catchError(this.handleError<Page>('deleteKnowledgeBaseSpace'))
    );
  }

  /** PUT: update the knowledgeBaseSpace on the server */
  updateKnowledgeBaseSpace(knowledgeBaseSpace: any, id: number): Observable<any> {
    this.setHeader(true);
    return this.http.put(`${this.base_url}/${id}`, knowledgeBaseSpace, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated knowledgeBaseSpace id=${knowledgeBaseSpace.id}`)),
      catchError(this.handleError<any>('updateKnowledgeBaseSpace'))
    );
  }

   /** Patch: update the KnowledgeBaseSpace on the server */
   editKnowledgeBaseSpace(data: any): Observable<any> {
    return this.http.patch(`${this.base_url}/page/${data.id}/${data.param}/${data.value}`, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`edit KnowledgeBaseSpace id=${data.id}`)),
      catchError(this.handleError<any>('editKnowledgeBaseSpace'))
    );
  }

}
