import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface IAutoCompleteItem {
    id: number
    text: string
}


@Component({
    selector: 'app-autocomplete',
    templateUrl: 'autocomplete.component.html'
})
export class AutocompleteComponent implements OnInit {

    @Input() placeholder: string = 'Search...'
    @Input() data: IAutoCompleteItem[] = []
    @Input() isLoading = false

    @Output() onChangeSearch: EventEmitter<string> = new EventEmitter<string>()
    @Output() selectEvent: EventEmitter<string> = new EventEmitter<string>()
    @Output() onFocused: EventEmitter<string> = new EventEmitter<string>()

    constructor() { }

    ngOnInit() { }
}