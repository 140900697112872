import {
  Component,
  Input,
  Output,
  EventEmitter, ChangeDetectorRef
} from '@angular/core';
import Chart from 'chart.js';
import 'rxjs/add/operator/map';


@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html'
})
export class PieChartComponent {

  @Input() chartName;
  @Input() chartId;
  @Input() stacked = false;
  @Input() chartType = 'pie';
  @Output() changeInterval: EventEmitter<any> = new EventEmitter();

  isHidden = true;
  chart = null;

  chartOptions = {};

  colorsLoaded = [];
  colors = ['#FF4612', '#666666', '#FFA000', '#D7D7D7',
    '#FFD200', '#ff9b80', '#cc2c00', '#ffd6cc',
    '#660000', '#995c00', '#ccff99'];

  constructor(private ref: ChangeDetectorRef) {
  }

  initializeNewChart(data) {
    const ctx = document.getElementById(this.chartId);

    this.chartOptions['legend'] = {
      position: 'bottom'
    };

    if (this.stacked) {
      this.chartOptions['scales'] = {
        xAxes: [{
          stacked: true,
        }],
        yAxes: [{
          stacked: true
        }]
      };
    }

    // overriding labels and datalabels plugins that were used specifically for repayment chart, but somehow
    // are added globally when opening dashboards for the first time from other page (not directly opened or refreshed)
    // if you need to use this in the future, make it optional via Inputs
    this.chartOptions['plugins'] = {
      labels: {
        render: () => {}
      },
      datalabels: {
        display: false
      }
    }

    this.chart = new Chart(ctx, {
      type: this.chartType,
      data: {
        labels: data.labels,
        datasets: data.datasets
      },
      options: this.chartOptions
    });
  }

  updateChart(data) {
    this.chart.config.data = data;
    this.chart.update();
  }

  drawChart(data) {
    if (this.chart) {
      this.updateChart(data);
    } else {
      this.initializeNewChart(data);
    }

    if (this.isHidden) {
      this.isHidden = false;
      this.ref.detectChanges();
    }
  }

  renderChart(data) {
    this.initColorsPalette();
    this.drawChart(this.mapResponseData(data));
  }

  mapResponseData(data) {
    const chartData = {
      labels: [],
      datasets: []
    };


    const keys = Object.keys(data);
    if (keys && keys.length > 0) {

      chartData.labels = keys;

      const values = [];

      keys.forEach(key => {
        values.push(data[key].count);
      });

      const pieChartColors = [...this.colorsLoaded];
      chartData.datasets.push({
          data: values,
          backgroundColor: pieChartColors
        });
    }

    return chartData;
  }

  initColorsPalette() {
    this.colorsLoaded = [...this.colors];
    for (let i = 0; i < 50; i++) {
      this.colorsLoaded.push(this.getRandomColorHsl());
    }
  }

  getRandomColorHsl() {
    return 'hsl(' + 100 * Math.random() + ',' +
      (25 + 70 * Math.random()) + '%,' +
      (65 + 10 * Math.random()) + '%)';
  }

  getRandomColorHex() {
    const hex = '0123456789ABCDEF';
    let color = '#'; // 10% opacity
    for (let i = 1; i <= 6; i++) {
      color += hex[Math.floor(Math.random() * 16)];
    }
    return color;
  }
}
