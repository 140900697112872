import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Renderer2
} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {PermissionResolver} from '@appServices/permission-resolver/permission-resolver.service';
import {Observable} from 'rxjs/Observable';
import { IntegrationsDashboardService } from '@appServices/core-job-scheduler/integrations-dashboard/integrations-dashboard.service';
import { forkJoin } from 'rxjs';
import { JobService } from '@appServices/core-job-scheduler/job/job.service';
import TimeZone from 'app/util/timezone';

@Component({
  selector: 'app-integrations-dashboard',
  templateUrl: './integrations-dashboard.component.html',
  styleUrls: ['./integrations-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntegrationsDashboardComponent implements OnInit {

  @Input() tenantId: number;

  paymentsPerProvider = [];
  paymentsPerSkuType = [];
  payoutsPerApi;
  

  tableDetailOpen = false;

  rows = [];
  perPage = 100;

  loading = false
  selected = [];

  showTenantName = false;

  loading$: Observable<boolean>;

  constructor(
    private renderer: Renderer2,
    private permissionResolverService: PermissionResolver,
    private integrationsDashboardService: IntegrationsDashboardService,
    private jobService: JobService,
    private ref: ChangeDetectorRef,
    private toastr: ToastrService
  ) {}


  onTableClick() {
    if (this.tableDetailOpen) {
      this.renderer.removeClass(document.body, 'table_collapse');
    } else {
      this.renderer.addClass(document.body, 'table_collapse');
    }
    this.tableDetailOpen = !this.tableDetailOpen;
  }

  reloadTable() {
    this.loadDashboards();
  }

  setPage() {
    this.loadDashboards();
  }

  loadDashboards() {
    this.loading = true;
    const paymentsPerProvider$ = this.integrationsDashboardService.getLatestCountsForPaymentsPerProvider(this.tenantId);
    const paymentsPerSkuType$ = this.integrationsDashboardService.getLatestCountsForPaymentsPerSkuType(this.tenantId);
    const payoutsPerApi$ = this.integrationsDashboardService.getLatestCountsForPayoutsPerApi(this.tenantId);

    forkJoin([paymentsPerProvider$, paymentsPerSkuType$, payoutsPerApi$]).subscribe(
      ([paymentsPerProvider, paymentsPerSkuType, payoutsPerApi]) => {
        this.paymentsPerProvider = paymentsPerProvider;
        this.paymentsPerSkuType = paymentsPerSkuType;
        this.payoutsPerApi = payoutsPerApi;

        this.loading = false;
        this.ref.detectChanges();
      },
      _ => {
        this.loading = false;
      }
    );
  }

  has(permission) {
    return this.permissionResolverService.have(permission);
  }

  ngOnInit() {
    this.showTenantName = this.tenantId == null;
    this.loadDashboards();
  }

  triggerDashboardJob() {
    this.toastr.success("Data is loading... Please wait...");
    this.jobService.triggerJob("integrations-status-dashboard-job").subscribe(_ => {
      setTimeout(() => {
        this.loadDashboards();
        this.toastr.success("Data has been loaded");
      }, 1000);
    });
  }

  formatDate = TimeZone.getFormatedTimezoneFromDate;

}
