export function __formatText__(status: string) {
  switch (status) {
    case 'CALL_IN_PROGRESS':
      return { txt: 'Call In Progress', class: 'progress-cls' };
    case 'PENDING':
      return { txt: 'Pending', class: 'pending-cls' };
    case 'ACCEPTED':
      return { txt: 'Accepted', class: 'cls-accept' };
    case 'REJECTED':
      return { txt: 'Rejected', class: 'cls-reject' };
    case 'REQUESTED_CALL_BACK':
      return { txt: 'Callback', class: 'callback-cls' };
    case 'ASSIGNED':
      return { txt: 'Assigned', class: 'assign-cls' };
    case 'CONVERTED':
      return { txt: 'Converted', class: 'convert-cls' };
    case 'ASSIGNED_TSE':
        return { txt: ' Assigned TSE', class: 'convert-cls' };
    case 'CANCELLED':
        return { txt: 'Cancelled', class: 'callback-cls' };
    default:
      return null;
  }
}