import {Action} from '@ngrx/store';

import {PurchasingOrder} from '@appModels/core-inventory/purchasing-order/purchasing-order';

export enum PurchasingOrderActionTypes {
  purchasingOrderError = '[PurchasingOrder] error',
  purchasingOrderCreate = '[PurchasingOrder] purchasingOrderCreate',
  purchasingOrderOk = '[PurchasingOrder] purchasingOrderOk',
  purchasingOrderDoNothing = '[PurchasingOrder] purchasingOrderDoNothing'

}



export class PurchasingOrderCreate implements Action {
  readonly type = PurchasingOrderActionTypes.purchasingOrderCreate;

  constructor(public payload: PurchasingOrder) {
  }
}




export class PurchasingOrderError implements Action {
  readonly type = PurchasingOrderActionTypes.purchasingOrderError;

  constructor(public payload: any) {
  }
}


export class PurchasingOrderDoNothing implements Action {
  readonly type = PurchasingOrderActionTypes.purchasingOrderDoNothing;

  constructor() {
  }
}

export class PurchasingOrderOk implements Action {
  readonly type = PurchasingOrderActionTypes.purchasingOrderOk;

  constructor() {
  }
}

export type PurchasingOrderActions =
  | PurchasingOrderCreate
  | PurchasingOrderOk
  | PurchasingOrderError
  | PurchasingOrderDoNothing

