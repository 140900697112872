import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {DelinquencyDefinition} from '@appModels/core-accounting/delinquency-definition/delinquency-definition';

import {CoreAccountingService} from '../core-accounting.service';


@Injectable({ providedIn: 'root' })
export class DelinquencyDefinitionService extends CoreAccountingService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'contractdelinquencydefinition');
  }

  /** GET delinquencyDefinitions from the server */
  // getDelinquencyDefinitions(query?): Observable<DelinquencyDefinition[]> {
  //   let params = new HttpParams();
  //   if (query !== undefined){
  //     // {page:1, size:10, sort: '' }
  //     Object.keys(query).forEach(val => {
  //       params = params.append(val, query[val]);
  //     });
  //   }
  //   return this.http.get<any>(this.base_url + '/', { headers: this.headers }).pipe(
  //     tap(delinquencyDefinitions => this.log(`fetched delinquencyDefinitions`)),
  //     catchError(this.handleError('getDelinquencyDefinitions', []))
  //   );
  // }

  getDelinquencyDefinitions(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + '/tenant/' + localStorage.getItem('tenant'), {
      params,
      headers: this.headers
    }).pipe(
      tap(delinquencyDefinitions => this.log(`fetched delinquencyDefinitions`)),
      catchError(this.handleError('getDelinquencyDefinitions', []))
    );
  }

  getDelinquencyDefinitionsByTenantId(id: number): Observable<DelinquencyDefinition[]> {
    return this.http.get<DelinquencyDefinition[]>(this.base_url + '/tenant/' + id, {headers: this.headers}).pipe(
      tap(delinquencyDefinitions => this.log(`fetched delinquencyDefinitions`)),
      catchError(this.handleError('getDelinquencyDefinitions', []))
    );
  }

  getAnyDelinquencyDefinitionsByTenantId(id: number): Observable<any> {
    return this.http.get<any>(this.base_url + '/tenant/' + id, {headers: this.headers}).pipe(
      tap(delinquencyDefinitions => this.log(`fetched delinquencyDefinitions`)),
      catchError(this.handleError('getDelinquencyDefinitions', []))
    );
  }

  /** GET delinquencyDefinition by id. Return `undefined` when id not found */
  getDelinquencyDefinitionNo404<Data>(id: number): Observable<DelinquencyDefinition> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<DelinquencyDefinition[]>(url).pipe(
      map(delinquencyDefinitions => delinquencyDefinitions[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} delinquencyDefinition id=${id}`);
      }),
      catchError(this.handleError<DelinquencyDefinition>(`getDelinquencyDefinition id=${id}`))
    );
  }

  /** GET delinquencyDefinition by id. Will 404 if id not found */
  getDelinquencyDefinition(id: number): Observable<DelinquencyDefinition> {
    const url = `${this.base_url}/${id}/`;
    return this.http.get<DelinquencyDefinition>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched delinquencyDefinition id=${id}`)),
      catchError(this.handleError<DelinquencyDefinition>(`getDelinquencyDefinition id=${id}`))
    );
  }

  /** GET delinquencyDefinition by id. Will 404 if id not found */
  getDelinquencyDefinitionByTenantProduct(id: number): Observable<any> {
    const url = `${this.base_url}tenantproductcatalogue/${id}/`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched delinquencyDefinition id=${id}`)),
      catchError(this.handleError<any>(`getDelinquencyDefinition id=${id}`))
    );
  }

  /** GET delinquencyDefinition by id. Will 404 if id not found */
  getDelinquencyDefinitionByTenantCatalogue(id: number): Observable<any> {
    const url = `${this.base_url}/tenant/productcatalogue/${id}/`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched delinquencyDefinition id=${id}`)),
      catchError(this.handleError<any>(`getDelinquencyDefinition id=${id}`))
    );
  }

  /** GET delinquencyDefinition by tenant id. Will 404 if id not found */
  getDelinquencyDefinitionByTenant(query?): Observable<DelinquencyDefinition[]> {
    let params = new HttpParams();
    if (query !== undefined){
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const url = `${this.base_url}/tenant/` + localStorage.getItem('tenant');
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched delinquencyDefinition id=`)),
      catchError(this.handleError(`getDelinquencyDefinition `, []))
    );
  }

  /** GET delinquencyDefinition by product id. Will 404 if id not found */
  getDelinquencyDefinitionProductCatalogue(id: number): Observable<any> {
    // const id = localStorage.getItem('tenant');
    const url = `${this.base_url}/tenant/productcatalogue/${id}/`;
    return this.http.get(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched delinquencyDefinitions by product catalogue id=${id}`)),
      catchError(this.handleError(`getDelinquencyDefinitionByProductCatalogue id=${id}`))
    );
  }

  /* GET delinquencyDefinitions whose name contains search term */
  searchDelinquencyDefinitions(term: string): Observable<DelinquencyDefinition[]> {
    if (!term.trim()) {
      // if not search term, return empty delinquencyDefinition array.
      return of([]);
    }
    return this.http.get<DelinquencyDefinition[]>(`api/delinquencyDefinitions/?name=${term}`).pipe(
      tap(_ => this.log(`found delinquencyDefinitions matching "${term}"`)),
      catchError(this.handleError<DelinquencyDefinition[]>('searchDelinquencyDefinitions', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new delinquencyDefinition to the server */
  addDelinquencyDefinition(delinquencyDefinition: DelinquencyDefinition): Observable<DelinquencyDefinition> {
    return this.http.post<DelinquencyDefinition>(this.base_url + '/', delinquencyDefinition, { headers: this.headers }).pipe(
      tap((delinquencyDefinition: DelinquencyDefinition) => this.log(`added delinquencyDefinition w/ id=${delinquencyDefinition.id}`)),
      catchError(this.handleError<DelinquencyDefinition>('addDelinquencyDefinition'))
    );
  }

  /** POST: add a new delinquencyDefinition to the server */
  addBundledDelinquencyDefinition(data: any): Observable<DelinquencyDefinition> {
    return this.http.patch<any>(`${this.base_url}/${data.delinquencyDefinitionId}/tenantproductcatalogue/${data.tenantProductCatalogueId}/bundled/${data.isBundled}` + '/', { headers: this.headers }).pipe(
      tap((delinquencyDefinition: any) => this.log(`added delinquencyDefinition w/ id=${delinquencyDefinition.id}`)),
      catchError(this.handleError<any>('addDelinquencyDefinition'))
    );
  }

  // addDelinquencyDefinition(delinquencyDefinition: DelinquencyDefinition): Observable<DelinquencyDefinition> {
  //   return this.http.post<DelinquencyDefinition>(this.base_url + '/', delinquencyDefinition, { headers: this.headers }).pipe(
  //     tap((newDelinquencyDefinition: DelinquencyDefinition) => {
  //       this.log(`added delinquencyDefinition w/ id=${newDelinquencyDefinition.tenantId}`);
  //       // patch info
  //       this.editDelinquencyDefinition({ value: delinquencyDefinition.id, id: newDelinquencyDefinition.tenantId, param: 'tenant' }).subscribe(data => {
  //       }, error => console.log(error, 'delinquencyDefinition error'));
  //     }),
  //     catchError(this.handleError<DelinquencyDefinition>('addDelinquencyDefinition'))
  //   );
  // }

  /** POST: add a new delinquencyDefinition to the server */
  addDelinquencyDefinitionAdditionalInfo(delinquencyDefinition: any): Observable<{}> {
    return this.http.post(`${this.base_url}/additionalInfo/${delinquencyDefinition.id}`, delinquencyDefinition.value, { headers: this.headers }).pipe(
      tap((res) => this.log(`added delinquencyDefinition  info `)),
      catchError(this.handleError('addDelinquencyDefinition'))
    );
  }

  /** DELETE: delete the delinquencyDefinition from the server */
  deleteDelinquencyDefinition(delinquencyDefinition: DelinquencyDefinition | number): Observable<DelinquencyDefinition> {
    const id = typeof delinquencyDefinition === 'number' ? delinquencyDefinition : delinquencyDefinition.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<DelinquencyDefinition>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted delinquencyDefinition id=${id}`)),
      catchError(this.handleError<DelinquencyDefinition>('deleteDelinquencyDefinition'))
    );
  }

  /** PUT: update the delinquencyDefinition on the server */
  updateDelinquencyDefinition(delinquencyDefinition: DelinquencyDefinition): Observable<any> {
    return this.http.put(`${this.base_url}/${delinquencyDefinition.id}`, delinquencyDefinition, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated delinquencyDefinition id=${delinquencyDefinition.id}`)),
      catchError(this.handleError<any>('updateDelinquencyDefinition'))
    );
  }

  /** Patch: update the delinquencyDefinition on the server */
  editDelinquencyDefinition(data:any): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`edit delinquencyDefinition id=${data.id}`)),
      catchError(this.handleError<any>('editDelinquencyDefinition'))
    );
  }

}
