import { CommonModule } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  inject,
} from "@angular/core";
import { FormsModule } from "@angular/forms";

import { takeUntil } from "rxjs/operators";
import { Subject, combineLatest } from "rxjs";
import { PopoverModule } from 'ngx-bootstrap/popover';

import { TranslateModule } from "@ngx-translate/core";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { MatCheckboxModule } from "@angular/material/checkbox";

import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { RouterModule } from "@angular/router";

import TimeZone from "app/util/timezone";
import { Page } from "@appModels/shared/page";
import { upsellEligibityStatus } from "./__utils__/__variables__";
import { __formatText__} from "./__utils__/__formatText__";
import { CrmUpsellModalComponent } from "./crm-upsell-modal/crm-upsell-modal.component";
import { UpsellProductService } from "@appServices/core-crm/upsell/upsell-products.service";
import Helpers from "app/util/helpers";
import { TooltipModule } from "ngx-bootstrap/tooltip";

@Component({
  selector: "app-crm-upsell-eligibity",
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatCheckboxModule,
    NgxDatatableModule,
    MatDialogModule,
    FormsModule,
    RouterModule,
    PopoverModule,
    TooltipModule
  ],
  templateUrl: "./crm-upsell-eligibity.component.html",
  styleUrl: "./crm-upsell-eligibity.component.css",
})
export class CrmUpsellEligibityComponent implements OnInit, OnDestroy {
  destroyRef = inject(DestroyRef);
  destroy$ = new Subject<void>();
  lastLoaded = TimeZone.getFormatedTimezoneFormated();
  status = upsellEligibityStatus;

  optional = Helpers.optional;
  formatText =  __formatText__;
  pageNumber: any;

  filter = {
    countryAdministrativeZoneId: null,
    desiredGlobalProductId: null,
    accountNumber: null,
    customerName: null,
    phoneNumber: null,
    nationalId: null,
    salesAgentName: null,
    salesAgentId: null,
    upsellStatus: 1,
    callBackTimer: null,
    hotSaleType: 1
  };

  rows = [];
  temp = [];
  perPage = 10;
  searchType = 'accountNumber';
  searchTerm = null;

  paging = new Page();
  public upsellData = new Page();

  upsellCustomersContent = [];
  selectedStatusId = 6;
  upsellService = inject(UpsellProductService);

  constructor(
    public dialog: MatDialog,
    private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getCrmUpsellList(this.filter);
  }

  getCrmUpsellList(filter?) {
    const sendQuery = { page: 0, size: 10 };
    filter = filter || {};

    const filteredFilter = Object.fromEntries(
      Object.entries(filter).filter(([key, value]) => !(key === 'upsellStatus' && value === 0 || value === '0'))
    );
 
    const hasValidFilter = Object.values(filteredFilter).some(value => value !== null && value !== '');
  
    if (hasValidFilter) {
      for (const [key, value] of Object.entries(filteredFilter)) {
        if (value !== null && value !== '') {
          sendQuery[key] = value;
        }
      }
    }

    if (this.searchType && this.searchTerm && this.searchTerm.trim()) {
      sendQuery[this.searchType] = this.searchTerm.trim();
    }

    
  
    const upsellList$ = this.upsellService.getCustomerUpsellList(sendQuery);
    combineLatest([upsellList$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([list]) => {
        this.upsellData = list;
        this.upsellCustomersContent = [...list.content];
        this.ref.detectChanges();
      });
  }
  

  openAssignModal(row) {
    const dialogRef = this.dialog.open(CrmUpsellModalComponent, {
      width: "700px",
      data: row,
    });
    dialogRef
      .afterClosed()
      .subscribe((result) => {
        this.getCrmUpsellList({ page: this.pageNumber ?? 0, size: this.perPage, ...this.filter });
      });
  }

  setPage(pageInfo) {
    this.lastLoaded = TimeZone.getFormatedTimezoneFormated();
    this.getCrmUpsellList(this.filter);
  }

  setPagination(pageInfo) {
    this.lastLoaded = TimeZone.getFormatedTimezoneFormated();
    if (pageInfo.offset > 0) {
      this.pageNumber =  pageInfo.offset;
      this.getCrmUpsellList({ page: pageInfo.offset, size: this.perPage, ...this.filter })
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getItemNamesString(items, property) {
    if (!items || items.length === 0) {
      return '--';
    }
    return items.map(item => item[property]).join(', ');
  }

  searchByCallBackTimer() {
    this.filter.upsellStatus = (this.filter.upsellStatus === 5) ? null : 5;
    this.getCrmUpsellList(this.filter);
  }
}
