import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as fromReducer from '@appStore/reducers';
import * as fromSelectors from '@appStore/selectors';
import { LoginRequired } from '@appStore/actions/core-auth/auth/auth.actions';
import { Nav, SubNav } from '@appStore/actions/nav.actions';
import { nav } from '../../config/nav';
import { KnowledgeBasePageService } from '@appServices/core-knowledge/knowledge-base-page/knowledge-base-page.service';
import { GetPages } from '@appStore/actions/core-knowledge/page/page.actions';
import { Page } from '@appModels/core-knowledge-base/pages/page';
import { KnowledgeBaseSpaceService } from '@appServices/core-knowledge/knowledge-base-space/knowledge-base-space.service';

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = nav.main.knowledge;

@Injectable({ providedIn: 'root' })
export class CoreKnowledgeGuard  {
  pages$: Observable<Page[]>;
  constructor(
    private store: Store<fromReducer.auth.State>,
    private knowledgeBasePage: KnowledgeBasePageService,
    private knowledgeBaseSpace: KnowledgeBaseSpaceService,
  ) { }

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(fromSelectors.isAuthenticated),
      map(authed => {
        this.store.dispatch(new SubNav([]));
        // this.store.dispatch(new GetPages());
        this.knowledgeBaseSpace.getKnowledgeBaseSpaceByTenant().subscribe(data => {
          if (data) {
            let space = []
            this.addRoute(space);
            data.forEach(element => {
              space.push({
                path: `/knowledge-base/spaces/${element.id}`,
                url: `/knowledge-base/spaces/${element.id}`,
                title: `${element.title}`,
                icon: 'group_icon',
                class: '',
                moduleName: 'Knowledge Base',
                subMenu: element.pages.map(val => {
                  return {
                    path: `/knowledge-base/pages/${val.id}`,
                    url: `/knowledge-base/pages/${val.id}`,
                    title: `${val.title}`,
                    icon: '',
                    class: '',
                    subMenu: this.getChildPages(val)
                  }
                })
              })
            });
            this.store.dispatch(new Nav(space));
          }
        })

        if (localStorage.getItem('authorization') !== null) {
          return true;
        }
        this.store.dispatch(new LoginRequired());
        return false;
      })
    );
  }

  getChildPages(element) {
    return element.childPages.map(val => {
      return {
        path: `/knowledge-base/pages/${val.id}`,
        url: `/knowledge-base/pages/${val.id}`,
        title: `${val.title}`,
        icon: '',
        class: '',
        subMenu: this.getChildPages(val)
      };
    });
  }

  addRoute(space){
    space.push({
      path: `/knowledge-base/pages/reporting`,
      url: `/knowledge-base/pages/reporting`,
      title: `Reporting`,
      icon: 'reports_icons',
      class: '',
      moduleName: 'Knowledge Base',
      subMenu:[]
    });
    space.push({
      path: `/knowledge-base/pages/upload-takers`,
      url: `/knowledge-base/pages/upload-takers`,
      title: `Upload Quiz Takers`,
      icon: 'reports_icons',
      class: '',
      moduleName: 'Knowledge Base',
      subMenu:[]
    });
  }
}
