import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {Rate} from '@appModels/core-accounting/rate/rate';

import {CoreAccountingService} from '../core-accounting.service';


@Injectable({ providedIn: 'root' })
export class CustomerRateDetailService extends CoreAccountingService {


  constructor(
    private http: HttpClient,

  ) {super(http, '')}

 
  getRateByCustomer(ids: number[]): Observable<any> {
    const rateIds = ids.join(",");

    const query = {
        rateIds: rateIds
    }

    let params = new HttpParams();
      if (query !== undefined) {
        // {page:1, size:10, sort: '' }
        Object.keys(query).forEach(val => {
          params = params.append(val, query[val]);
        });
      }

    const url = `${this.base_url}getContractRateDetails`;
    return this.http.get<any>(url, {  params,
        headers: this.headers}).pipe(
      tap(_ => this.log(`fetched rate id=${rateIds}`)),
      catchError(this.handleError<any>(`getRate id=${rateIds}`))
    );
  }

}