import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter, Input,
  OnInit,
  Output,
  Renderer2,
  TemplateRef
} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromReducer from '@appStore/reducers';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ReportSchema} from '@appModels/core-reporting/report-schema/report-schema';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import TimeZone from '../../../util/timezone';
import {ReportSchemaService} from '@appServices/core-reporting/report-schema/report-schema.service';
import {PermissionResolver} from '@appServices/permission-resolver/permission-resolver.service';
import {ReportSchemaGetByTenant, ReportSchemaGetForUser} from '@appStore/actions/core-reporting/report-schema/report-schema.actions';
import * as fromSelectors from '@appStore/selectors';
import {Observable} from 'rxjs/Observable';

@Component({
  selector: 'app-module-report-list',
  templateUrl: './module-report-list.component.html',
  styleUrls: ['./module-report-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModuleReportListComponent implements OnInit {

  @Input() moduleId: number;
  @Output() viewReport: EventEmitter<any> = new EventEmitter();

  loading$: Observable<boolean>;
  reportSchemas$: Observable<ReportSchema[]>;
  paging$: Observable<any>;

  modalRef: BsModalRef;

  lastLoaded = moment().format('ddd, MMM Do, hh:mm:ss a');
  formatDate = TimeZone.getFormatedTimezoneFromDate;

  selected = [];
  tableDetailOpen = false;
  page = null;
  perPage = 10;
  sortDirection: null;
  sortColumn: null;

  isAdmin = false;

  reportSchema: ReportSchema = {
    createdAt: null,
    updatedAt: null,
    fileName: null,
    report: null,
    tenantId: null,
    module: null,
    name: null,
    id: null
  };

  constructor(
    private store: Store<fromReducer.reportSchema.State>,
    private modalService: BsModalService,
    private reportSchemaService: ReportSchemaService,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private permissionResolverService: PermissionResolver
  ) { }

  ngOnInit(): void {
    this.isAdmin = this.permissionResolverService.isTenantAdmin() || this.permissionResolverService.isSuperUser();

    this.loadData();
  }

  can(action) {
    return this.permissionResolverService.setModuleName('Reporting').can(action);
  }

  reloadTable() {
    this.lastLoaded = moment().format('ddd, MMM Do, h:mm:ss a');
    this.loadData();
  }

  loadData(data?) {
    this.store.dispatch(this.isAdmin
      ? new ReportSchemaGetByTenant({...data, moduleId: this.moduleId})
      : new ReportSchemaGetForUser({...data, moduleId: this.moduleId}));

    this.reportSchemas$ = this.store.pipe(select(fromSelectors.getReportSchemas));
    this.paging$ = this.store.pipe(select(fromSelectors.getReportSchemaPaging));
    this.loading$ = this.store.pipe(select(fromSelectors.getReportSchemaLoading));
  }

  setPage(pageInfo) {
    this.page = pageInfo.offset;
    this.lastLoaded = moment().format('ddd, MMM Do, h:mm:ss a');
    this.loadData({
      page: this.page,
      size: this.perPage,
      sortDirection: this.sortDirection,
      sortColumn: this.sortColumn
    });
  }

  onSort(sortInfo) {
    if (sortInfo.sorts.length > 0) {
      this.sortDirection = sortInfo.sorts[0].dir;
      this.sortColumn = sortInfo.sorts[0].prop;
    } else {
      this.sortDirection = null;
      this.sortColumn = null;
    }
    this.loadData({
      size: this.perPage,
      sortDirection: this.sortDirection,
      sortColumn: this.sortColumn
    });
  }

  editReport(id) {
    this.router.navigateByUrl(`/reporting/manage-reports/update/${id}`);
  }

  deleteReport(reportSchema) {
    this.reportSchemaService.deleteReportSchema(reportSchema.id).subscribe(response => {
      this.toastr.success('Report schema deleted successfully!', 'Successful!');
      this.modalRef.hide();
      this.loadData();

      this.changeDetectorRef.detectChanges();
    });
  }

  onTableClick(report) {
    this.reportSchema = report.selected !== undefined ? report.selected[0] : report;
    if (this.tableDetailOpen) {
      this.renderer.removeClass(document.body, 'table_collapse');
    } else {
      this.renderer.addClass(document.body, 'table_collapse');
    }
    this.tableDetailOpen = !this.tableDetailOpen;
  }

  openAction(template: TemplateRef<any>, reportSchema) {
    this.reportSchema = reportSchema;
    this.modalRef = this.modalService.show(template);
  }

  viewReportEvent(report) {
    this.viewReport.emit(report);
  }
}
