import { Injectable } from '@angular/core';
import { CoreJobSchedulerService } from '../core-job-scheduler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class IntegrationsDashboardService extends CoreJobSchedulerService {
  constructor(
    private http: HttpClient,
  ) {
    super(http, 'integrationsstatusdashboard');
  }


  getLatestCountsForPaymentsPerProvider(tenantId: Number): Observable<any> {
    let url = `${this.base_url}/payments/perprovider`;
    if (tenantId != null) url += ('?tenantId=' + tenantId);
    return this.http.get<any>(url, { headers: this.headers });
  }

  getLatestCountsForPaymentsPerSkuType(tenantId: Number): Observable<any> {
    let url = `${this.base_url}/payments/perskutype`;
    if (tenantId != null) url += ('?tenantId=' + tenantId);
    return this.http.get<any>(url, { headers: this.headers });
  }

  getLatestCountsForPayoutsPerApi(tenantId: Number): Observable<any> {
    let url = `${this.base_url}/payouts/perapi`;
    if (tenantId != null) url += ('?tenantId=' + tenantId);
    return this.http.get<any>(url, { headers: this.headers });
  }

}
