import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';


import {CoreCrmService} from '../../core-crm.service';
import { CustomerTelesales } from '@appModels/core-crm/customer-telesales/customer-telesales';


@Injectable({providedIn: 'root'})
export class CustomerTelesalesService extends CoreCrmService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, 'call');
  }

   /** POST: add a new Customer Telesales call to the server */
   addCall(customerTelesales: CustomerTelesales): Observable<CustomerTelesales> {
    return this.http.post<CustomerTelesales>(this.base_url + '/teleSales', customerTelesales, { headers: this.headers }).pipe(
      tap((customerTelesales: CustomerTelesales) => this.log(`added customerTelesales w/ id=${customerTelesales.id}`)),
      catchError(this.handleError<CustomerTelesales>('addCustomerTelesales'))
    );
  }

  /** POST: assign a new Customer Telesales call to the server */
  assignCall(callId, salesStaffId): Observable<any> {
    return this.http.post(`${this.base_url}/assignTeleSales/${callId}/${salesStaffId}`, {}, {observe: 'response', responseType: 'text'})
  }

  /** GET Telesales from the server */
  getTelesales(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + '/telesales/tenant/' + localStorage.getItem('tenant'), {
      params,
      headers: this.headers
    }).pipe(
      tap(callLogs => this.log(`fetched telesales logs`)),
      catchError(this.handleError('getTelesales', []))
    );
  }

  /** GET telesales statuses from the server */
  getStatuses(): Observable<any> {
    return this.http.get<any>(`${this.base_url}/telesales/statuses`, {
      headers: this.headers
    }).pipe(
      tap(statuses => this.log(`fetched statuses`)),
      catchError(this.handleError('statuses', []))
    );
  }

  getAgentsForTelesalesAssignation(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + '/telesales/getstaff', {
      params,
      headers: this.headers
    }).pipe(
      tap(callLogs => this.log(`fetched telesales logs`)),
      catchError(this.handleError('getTelesalesAgents', []))
    );
  }
}
