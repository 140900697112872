import {ChangeDetectorRef, Component, Input, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-s3-image',
  template: `<img [src]="image || (defaultAvatar ? defaultAvatarUrl : '')"
                  [width]="width"
                  [alt]="alt">`
})
export class S3ImageComponent {
  @Input() promise: Promise<any>;
  @Input() width: number = 90;
  @Input() alt: string = '';
  @Input() defaultAvatar: boolean = false;

  readonly defaultAvatarUrl: string = 'assets/img/avatar.png';
  image: string | ArrayBuffer | null;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges) {
    // only run when property "promise" changed, so we don't try to load image when component
    // is initially rendered
    if (changes['promise'] && this.promise) {
      this.loadImage();
    }
  }

  loadImage() {
    // this.promise prop that is passed here is expected to be from service that makes request
    // to backend to get image as blob. Usually, you can use your observable with .toPromise()
    // as we don't expect image fetching to be data stream, but rather just one call
    this.promise.then((blob: Blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.image = reader.result;
        this.changeDetectorRef.detectChanges();
      }
    })
  }
}
