import {Component, EventEmitter, Input, Output} from '@angular/core';
import {debounce} from 'lodash';

@Component({
  selector: 'app-searchable-api-dropdown',
  template: `
      <angular2-multiselect [data]="itemList"
                            [(ngModel)]="selectedItems"
                            [settings]="settings"
                            (onSelect)="onItemSelect($event)"
                            (onDeSelect)="onItemDeSelect($event)"
                            (onSelectAll)="onSelectAllItems($event)"
                            (onDeSelectAll)="onDeSelectAllItems($event)">

          <c-badge>
              <ng-template let-item="item">
                  <label style="margin: 0; color:white; background-color:#FF4612; padding: 4px 22px 4px 8px;border-radius: 7px!important;" >{{ item[field] }}</label>
              </ng-template>
          </c-badge>

          <c-search>
              <ng-template>
                  <input type="text" (keyup)="onSearch($event)" placeholder="Search"
                         style="border: none;width: 100%; height: 100%;outline: none;"/>
              </ng-template>
          </c-search>

          <c-item>
              <ng-template let-item="item">
                  <label>{{ item[field] }}</label>
              </ng-template>
          </c-item>
      </angular2-multiselect>`
})
export class SearchableApiDropdownComponent {

  @Input() field: string = 'name';
  @Input()
  set items(items: any[]) {
    this.itemList = Object.assign([], items);
  }
  @Input()
  set singleSelection(singleSelection: boolean) {
    this.settings.singleSelection = singleSelection;
  }

  @Output() onChangeSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeselect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelectAll: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeselectAll: EventEmitter<any> = new EventEmitter<any>();

  itemList: any = [];
  selectedItems: any[] = [];

  settings = {
    text: "Select",
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    classes: "myclass custom-class",
    noDataLabel: "No data",
    enableSearchFilter: true,
    singleSelection: false,
    autoPosition: false
  };

  // debounce around search, so we don't emit event to make API call on each keystroke
  debounceSearch = debounce((search) => this.emitSearch(search), 300);

  constructor() { }

  onSearch(evt: any) {
    this.debounceSearch(evt.target.value);
  }

  onItemSelect(item: any) {
    this.onSelect.emit(item);
  }

  onItemDeSelect(item: any) {
    this.onDeselect.emit(item);
  }

  onSelectAllItems(items: any) {
    this.onSelectAll.emit(items);
  }

  onDeSelectAllItems(items: any) {
    this.onDeselectAll.emit(items);
  }

  emitSearch(search) {
    this.onChangeSearch.emit(search);
  }
}
