import {Component, ViewChild, Input} from '@angular/core';
import {environment} from '../../../environments/environment';
import {FlexmonsterPivot} from 'ngx-flexmonster';

@Component({
  selector: 'app-flexmonster-pivot-table',
  template: `
    <fm-pivot #pivot
              [licenseKey]="environment.flexmonster_license"
              [report]="report">
    </fm-pivot>`
})
export class FlexmonsterPivotTableComponent {
  @ViewChild('pivot') pivot: FlexmonsterPivot;

  @Input() report: Flexmonster.Report;

  environment = environment;

}
