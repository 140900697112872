// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rounded-checkbox {
  border-radius: 50%!important;
  height:1rem!important;
  width:1rem!important;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
}

.rounded-checkbox:checked {
  background-color: #ff4613;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNyZWF0ZS10ZWFtLWNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSw0QkFBNEI7RUFDNUIscUJBQXFCO0VBQ3JCLG9CQUFvQjtFQUNwQixzQkFBc0I7RUFDdEIsZ0JBQWdCO0VBQ2hCLHdCQUF3QjtFQUN4QixhQUFhO0FBQ2Y7O0FBRUE7RUFDRSx5QkFBeUI7QUFDM0IiLCJmaWxlIjoiY3JlYXRlLXRlYW0tY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5yb3VuZGVkLWNoZWNrYm94IHtcbiAgYm9yZGVyLXJhZGl1czogNTAlIWltcG9ydGFudDtcbiAgaGVpZ2h0OjFyZW0haW1wb3J0YW50O1xuICB3aWR0aDoxcmVtIWltcG9ydGFudDtcbiAgYm9yZGVyOiAxcHggc29saWQgI2RkZDtcbiAgYXBwZWFyYW5jZTogbm9uZTtcbiAgLXdlYmtpdC1hcHBlYXJhbmNlOiBub25lO1xuICBvdXRsaW5lOiBub25lO1xufVxuXG4ucm91bmRlZC1jaGVja2JveDpjaGVja2VkIHtcbiAgYmFja2dyb3VuZC1jb2xvcjogI2ZmNDYxMztcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/containers/ticketing/components/teams/create-team-component/create-team-component.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,qBAAqB;EACrB,oBAAoB;EACpB,sBAAsB;EACtB,gBAAgB;EAChB,wBAAwB;EACxB,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B;;AAEA,gvBAAgvB","sourcesContent":[".rounded-checkbox {\n  border-radius: 50%!important;\n  height:1rem!important;\n  width:1rem!important;\n  border: 1px solid #ddd;\n  appearance: none;\n  -webkit-appearance: none;\n  outline: none;\n}\n\n.rounded-checkbox:checked {\n  background-color: #ff4613;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
