import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {Rate} from '@appModels/core-accounting/rate/rate';

import {CoreAccountingService} from '../core-accounting.service';


@Injectable({ providedIn: 'root' })
export class RateService extends CoreAccountingService {


  constructor(
    private http: HttpClient,

  ) {
    super(http, 'rate');
  }

  /** GET rates from the server */
  // getRates(query?): Observable<Rate[]> {
  //   let params = new HttpParams();
  //   if (query !== undefined){
  //     // {page:1, size:10, sort: '' }
  //     Object.keys(query).forEach(val => {
  //       params = params.append(val, query[val]);
  //     });
  //   }
  //   return this.http.get<any>(this.base_url + '/', { headers: this.headers }).pipe(
  //     tap(rates => this.log(`fetched rates`)),
  //     catchError(this.handleError('getRates', []))
  //   );
  // }

  getRates(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(this.base_url + '/tenant/' + localStorage.getItem('tenant'), {
      params,
      headers: this.headers
    }).pipe(
      tap(rates => this.log(`fetched rates`)),
      catchError(this.handleError('getRates', []))
    );
  }

  getRatesByTenantId(id: number): Observable<Rate[]> {
    return this.http.get<Rate[]>(this.base_url + '/tenant/' + id, {headers: this.headers}).pipe(
      tap(rates => this.log(`fetched rates`)),
      catchError(this.handleError('getRates', []))
    );
  }

  getAnyRatesByTenantId(id: number): Observable<any> {
    return this.http.get<any>(this.base_url + '/tenant/' + id + '?page=0&size=9999', {headers: this.headers}).pipe(
      tap(rates => this.log(`fetched rates`)),
      catchError(this.handleError('getRates', []))
    );
  }

  /** GET rate by id. Return `undefined` when id not found */
  getRateNo404<Data>(id: number): Observable<Rate> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<Rate[]>(url).pipe(
      map(rates => rates[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} rate id=${id}`);
      }),
      catchError(this.handleError<Rate>(`getRate id=${id}`))
    );
  }

  /** GET rate by id. Will 404 if id not found */
  getRate(id: number): Observable<Rate> {
    const url = `${this.base_url}/${id}/`;
    return this.http.get<Rate>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched rate id=${id}`)),
      catchError(this.handleError<Rate>(`getRate id=${id}`))
    );
  }

  /** GET rate by id. Will 404 if id not found */
  getRateDetails(id: number): Observable<any> {
    const url = `${this.base_url}/${id}/details`;
    return this.http.get<any>(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched rate id=${id}`)),
      catchError(this.handleError<any>(`getRate id=${id}`))
    );
  }

  /** GET rate by id. Will 404 if id not found */
  getRateByTenantProduct(id: number): Observable<any> {
    const url = `${this.base_url}tenantproductcatalogue/${id}/`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched rate id=${id}`)),
      catchError(this.handleError<any>(`getRate id=${id}`))
    );
  }

  /** GET rate by id. Will 404 if id not found */
  getProductCatalogues(id: number): Observable<any> {
    const url = `${this.base_url}/${id}/ratetenantproductcatalogues`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched product catalogues id=${id}`)),
      catchError(this.handleError<any>(`getProductCatalogues id=${id}`))
    );
  }

  /** GET rate by id. Will 404 if id not found */
  getRateByTenantCatalogue(id: number): Observable<any> {
    const url = `${this.base_url}/tenant/productcatalogue/${id}/`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched rate id=${id}`)),
      catchError(this.handleError<any>(`getRate id=${id}`))
    );
  }

  /** GET rate by tenant id. Will 404 if id not found */
  getRateByTenant(query?): Observable<Rate[]> {
    let params = new HttpParams();
    if (query !== undefined){
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const url = `${this.base_url}/tenant/` + localStorage.getItem('tenant');
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched rate id=`)),
      catchError(this.handleError(`getRate `, []))
    );
  }

  /** GET rate by product id. Will 404 if id not found */
  getRateProductCatalogue(id: number): Observable<any> {
    // const id = localStorage.getItem('tenant');
    const url = `${this.base_url}/tenant/productcatalogue/${id}/`;
    return this.http.get(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched rates by product catalogue id=${id}`)),
      catchError(this.handleError(`getRateByProductCatalogue id=${id}`))
    );
  }

  /** GET rate by product id. Will 404 if id not found */
  getRateProductCatalogueContractProduct(id: number, contractProductId: number): Observable<any> {
    // const id = localStorage.getItem('tenant');
    const url = `${this.base_url}/tenant/productcatalogue/${id}/contractproduct/${contractProductId}`;
    return this.http.get(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched rates by product catalogue id=${id}`)),
      catchError(this.handleError(`getRateByProductCatalogue id=${id}`))
    );
  }

  /** GET rate by product id. Will 404 if id not found */
  getRateProductCatalogueCustomer(id: number, customerId: number): Observable<any> {
    // const id = localStorage.getItem('tenant');
    const url = `${this.base_url}/tenant/productcatalogue/${id}/customer/${customerId}`;
    return this.http.get(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched rates by product catalogue id=${id}`)),
      catchError(this.handleError(`getRateByProductCatalogue id=${id}`))
    );
  }


  /* GET rates whose name contains search term */
  searchRates(term: string): Observable<Rate[]> {
    if (!term.trim()) {
      // if not search term, return empty rate array.
      return of([]);
    }
    return this.http.get<Rate[]>(`api/rates/?name=${term}`).pipe(
      tap(_ => this.log(`found rates matching "${term}"`)),
      catchError(this.handleError<Rate[]>('searchRates', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new rate to the server */
  addRate(rate: Rate): Observable<Rate> {
    return this.http.post<Rate>(this.base_url + '/', rate, { headers: this.headers }).pipe(
      tap((rate: Rate) => this.log(`added rate w/ id=${rate.id}`)),
      catchError(this.handleError<Rate>('addRate'))
    );
  }

  /** POST: add a new rate to the server */
  addBundledRate(data: any): Observable<Rate> {
    let bundledRateId = data.bundledRateId ? `?bundledRateId=${data.bundledRateId}` : '';
    return this.http.patch<any>(`${this.base_url}/${data.rateId}/tenantproductcatalogue/${data.tenantProductCatalogueId}/bundled/${data.isBundled}` + '/' + bundledRateId, { headers: this.headers }).pipe(
      tap((rate: any) => this.log(`added rate w/ id=${rate.id}`)),
      catchError(this.handleError<any>('addRate'))
    );
  }

  removeBundledRate(data) {
    return this.http.delete<any>(`${this.base_url}/${data.rateId}/tenantproductcatalogue/${data.tenantProductCatalogueId}/`, { headers: this.headers }).pipe(
      tap((rate: any) => this.log(`deleted bundled rate`)),
      catchError(this.handleError<any>('removeRate'))
    );
  }

  /** PATCH: associate tax price with rate to the server */
  addTaxPrice(data: any, id: number): Observable<Rate> {
    return this.http.patch<any>(`${this.base_url}/${id}/taxPrice/`, data, { headers: this.headers }).pipe(
      tap((rate: any) => this.log(`associate tax price with rate w/ id=${rate.id}`)),
      catchError(this.handleError<any>('addRate'))
    );
  }

  /** POST: add a new rate to the server */
  saveRate(data: any): Observable<any> {
    return this.http.post<any>(this.base_url + '/complete', data, { headers: this.headers }).pipe(
      tap((rate: any) => this.log(`saved rate`)),
      catchError(this.handleError<any>('saveRate'))
    );
  }

  saveReplacementRate(data: any): Observable<any> {
    return this.http.post<any>(`${this.base_url}/complete`, data, {headers: this.headers, observe: 'response'})
  }

  /** PATCH: associate tax price with rate to the server */
  patchRate(data: any, id: number): Observable<any> {
    return this.http.patch<any>(`${this.base_url}/${id}/complete`, data, { headers: this.headers }).pipe(
      tap((rate: any) => this.log(`update rate w/ id=${rate.id}`)),
      catchError(this.handleError<any>('updateRate'))
    );
  }

  /** PATCH: associate tax price with rate to the server */
  updateTaxPrice(data: any, id: number): Observable<Rate> {
    return this.http.patch<any>(`${this.base_url}/${id}/taxPrice/update`, data, { headers: this.headers }).pipe(
      tap((rate: any) => this.log(`associate tax price with rate w/ id=${rate.id}`)),
      catchError(this.handleError<any>('addRate'))
    );
  }

  /** POST: add discount and penalty with rate to the server */
  addPenalty(data: any, id: number): Observable<any> {
    return this.http.post<any>(`${this.base_url}/discountAndPenalty/${id}`, data, { headers: this.headers }).pipe(
      tap((rate: any) => this.log(`add discount and penalty with rate w/ id=${rate.id}`)),
      catchError(this.handleError<any>('addDiscountRate'))
    );
  }

  /** GET discount and penalty by rate. Will 404 if id not found */
  getPenalty(id: number): Observable<any> {
    const url = `${this.base_url}/discountAndPenalty/${id}`;
    return this.http.get(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched rates by discountAndPenalty=${id}`)),
      catchError(this.handleError(`discountAndPenalty id=${id}`))
    );
  }
  /** GET rate by tax Price. Will 404 if id not found */
  getTaxPrice(id: number): Observable<any> {
    const url = `${this.base_url}/${id}/taxPrice/`;
    return this.http.get(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`fetched rates by taxPrice=${id}`)),
      catchError(this.handleError(`taxPrice id=${id}`))
    );
  }

  getReplacementRate(id: number): Observable<any> {
    const url = `${this.base_url}/replacementoptions/${id}`;
    return this.http.get(url,{ headers: this.headers }).pipe(
      tap(_ => this.log(`getReplacementRate`)),
      catchError(this.handleError(`getReplacementRate`))
    );
  }

  // addRate(rate: Rate): Observable<Rate> {
  //   return this.http.post<Rate>(this.base_url + '/', rate, { headers: this.headers }).pipe(
  //     tap((newRate: Rate) => {
  //       this.log(`added rate w/ id=${newRate.tenantId}`);
  //       // patch info
  //       this.editRate({ value: rate.id, id: newRate.tenantId, param: 'tenant' }).subscribe(data => {
  //       }, error => console.log(error, 'rate error'));
  //     }),
  //     catchError(this.handleError<Rate>('addRate'))
  //   );
  // }

  /** POST: add a new rate to the server */
  addRateAdditionalInfo(rate: any): Observable<{}> {
    return this.http.post(`${this.base_url}/additionalInfo/${rate.id}`, rate.value, { headers: this.headers }).pipe(
      tap((res) => this.log(`added rate  info `)),
      catchError(this.handleError('addRate'))
    );
  }

  /** DELETE: delete the rate from the server */
  deleteRate(rate: Rate | number): Observable<Rate> {
    const id = typeof rate === 'number' ? rate : rate.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<Rate>(url, { headers: this.headers }).pipe(
      tap(_ => this.log(`deleted rate id=${id}`)),
      catchError(this.handleError<Rate>('deleteRate'))
    );
  }

  /** PUT: update the rate on the server */
  updateRate(rate: Rate): Observable<any> {
    return this.http.put(`${this.base_url}/${rate.id}`, rate, { headers: this.headers }).pipe(
      tap(_ => this.log(`updated rate id=${rate.id}`)),
      catchError(this.handleError<any>('updateRate'))
    );
  }

  /** Patch: update the rate on the server */
  editRate(data: any): Observable<any> {
    return this.http.patch(`${this.base_url}/${data.id}/${data.param}/${data.value}`, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`edit rate id=${data.id}`)),
      catchError(this.handleError<any>('editRate'))
    );
  }

}
