import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { PermissionResolver } from "@appServices/permission-resolver/permission-resolver.service";
import { CallScriptService } from "@appServices/core-crm/call-script/call-script.service";

export interface Question {
  id: number;
  description: string;
  question: string;
}
export interface Score {
  scores: number;
}
@Component({
  selector: "call-script-container",
  templateUrl: "./call-script-container.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallScriptContainerComponent implements OnInit, OnChanges {
  @Input() customer;
  @Input() awaitingEscalation;
  @Input() showComponent;

  modalRef: BsModalRef;

  agentName = JSON.parse(localStorage.getItem("user")).firstName;

  callScoreCardQuestions = [];
  callScoreCardAnswers = [];
  currentQuestionIndex = 0;
  startQuestion = false;
  callScoreCardQuestionsAnswer = {};
  showTotalScore: boolean;
  scoreSum: any;
  @Output() scoreSaved = new EventEmitter<void>();
  showMessage: boolean = false;
  isMobileView = false;



  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private callScriptService: CallScriptService
  ) {}
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.checkScreenSize();
  }
  checkScreenSize() {
    this.isMobileView = window.innerWidth < 768; // Adjust the threshold as per your design
  }
  getCallQuestions(type) {
    this.callScriptService.getCallQuestions(type).subscribe((data) => {
      this.callScoreCardQuestions = data;
      this.callScoreCardQuestionsAnswer = this.callScoreCardQuestions.reduce(
        (acc, next) => {
          return {
            ...acc,
            [next.questionOrder]: {
              questionId: next.questionId,
              score: 0,
              isAnswered: false,
              customerCallScriptQuestionId: null,
            },
          };
        },
        {}
      );
    });
  }

  resetQuestionAnswers() {
    Object.keys(this.callScoreCardQuestionsAnswer).forEach((questionOrder) => {
      this.callScoreCardQuestionsAnswer[questionOrder] = {
        ...this.callScoreCardQuestionsAnswer[questionOrder],
        score: 0,
        isAnswered: false,
        customerCallScriptQuestionId: null,
      };
    });
  }

  getCallAnswers(customerId) {
    // ->
    this.resetQuestionAnswers();
    this.callScriptService
      .getCallAnswers(customerId, "KYC_CALL_SCORE_CARD")
      .subscribe((data) => {
        this.callScoreCardAnswers = data;
        if (
          this.callScoreCardAnswers.length ===
          this.callScoreCardQuestions.length
        ) {
          this.scoreSaved.emit();
          this.showMessage = false;
        } else {
          this.showMessage = true;
        }
        this.callScoreCardAnswers.forEach((answer) => {
          if (this.callScoreCardQuestionsAnswer[answer.questionOrder]) {
            this.callScoreCardQuestionsAnswer[answer.questionOrder].score =
              answer.score;
            this.callScoreCardQuestionsAnswer[answer.questionOrder].isAnswered =
              true;
            this.callScoreCardQuestionsAnswer[
              answer.questionOrder
            ].customerCallScriptQuestionId =
              answer.customerCallScriptQuestionId;
          }
        });
      });
  }

  saveTotalScore() {
    this.showTotalScore = true;
    // const answers: {score: number, questionId: number}[] = Object.values(this.callScoreCardQuestionsAnswer)
    // this.scoreSum = answers.reduce((acc, nxt) => {
    //   return acc + nxt.score
    // },0)
    // // @TODO save answer in bulk...
    // this.callScriptService.saveBulkScore(this.awaitingEscalation.id, answers).subscribe(
    //   data => {
    //     this.toastr.success('Score saved successfully!')
    //   }
    // )
  }

  saveScore() {
    const answers: {
      score: number;
      questionId: number;
      isAnswered: boolean;
      customerCallScriptQuestionId: number;
    }[] = Object.values(this.callScoreCardQuestionsAnswer);
    this.scoreSum = answers.reduce((acc, nxt) => {
      return acc + nxt.score;
    }, 0);

    const currentIndex = this.currentQuestionIndex;

    if (answers[currentIndex].isAnswered) {
      this.callScriptService
        .updateScore(
          answers[currentIndex].customerCallScriptQuestionId,
          answers[currentIndex].score
        )
        .subscribe((data) => {
          if (data != undefined) {
            this.toastr.success(
              "Score for question " +
                this.currentQuestionIndex +
                " updated successfully"
            );
            answers[currentIndex].isAnswered = true;
            this.checkIfAllHaveScore(answers);
          }
        });
    } else {
      this.callScriptService
        .saveScore(this.awaitingEscalation.id, answers[currentIndex])
        .subscribe(() => {
          this.toastr.success(
            "Score for question " +
              this.currentQuestionIndex +
              " saved successfully"
          );
          answers[currentIndex].isAnswered = true;
          this.checkIfAllHaveScore(answers);
        });
    }
  }

  checkIfAllHaveScore(answers: any[]): void {
    if (answers.every((answer) => answer.isAnswered)) {
      this.scoreSaved.emit();
      this.showMessage = false;
    }
  }

  displayQuestions() {
    this.startQuestion = true;
  }

  nextQuestion() {
    if (this.currentQuestionIndex < this.callScoreCardQuestions.length - 1) {
      this.currentQuestionIndex++;
    }
  }

  previousQuestion() {
    if (this.currentQuestionIndex > 0) {
      this.currentQuestionIndex--;
    }
  }

  ngOnInit() {
    this.renderer.removeClass(document.body, "table_collapse");
    this.getCallQuestions("KYC_CALL_SCORE_CARD");
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.customer) {
      this.getCallAnswers(this.customer.id);
    }
  }
}
