import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { VersionService } from "@appServices/version/version";
import * as Sentry from "@sentry/browser";
import { environment } from "environments/environment";
import { ToastrService } from "ngx-toastr";


Sentry.init({
  dsn: "https://91c82319e711486486b74c0dbe394603@o420823.ingest.us.sentry.io/5339702",
  environment: environment.name,
  enabled: environment.sentry_enabled,
});

const user = localStorage.getItem("user");
if (user) {
  Sentry.setUser({
    id: JSON.parse(user)?.id,
    email: JSON.parse(user)?.email,
    username: JSON.parse(user)?.userName,
  });
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}
  handleError(error) {
    const config = {
      TypeError: 100,
      SyntaxError: 101,
      ReferenceError: 102,
    };
    let code = null;

    if (error instanceof TypeError) {
      code = config["TypeError"];
    }

    if (error instanceof ReferenceError) {
      code = config["ReferenceError"];
    }

    if (error instanceof SyntaxError) {
      code = config["SyntaxError"];
    }

    if (localStorage.getItem("JwtErrorShown") === null) {
      this.injector
        .get(ToastrService)
        .error("There is an internal error, please try again later"),
        `${code ? "Error code: " + code : ""}`;
    }

    Sentry.setExtra("extra", {
      code: code,
    });

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      this.injector.get(VersionService).changeVersion();
      console.log("error");
    } else {
      Sentry.captureException(error.originalError || error);
    }
  }
}
