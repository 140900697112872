import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';
import * as Flexmonster from 'flexmonster';
import {ReportSchemaService} from '@appServices/core-reporting/report-schema/report-schema.service';
import {PermissionResolver} from '@appServices/permission-resolver/permission-resolver.service';
import TimeZone from '../../../util/timezone';
import * as moment from 'moment';
import {ActivatedRoute} from '@angular/router';
import {FlexmonsterPivotComponent} from '@appContainers/shared-component/flexmonster-pivot.component';

@Component({
  selector: 'app-module-report-layout',
  templateUrl: './module-report-layout.component.html',
  styleUrls: ['./module-report-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModuleReportLayoutComponent implements OnInit, OnDestroy {

  @ViewChild('flexmonster') flexmonster: FlexmonsterPivotComponent;

  subscriptions: Subscription[] = [];

  reportSchemaId = this.activatedRoute.params['_value']['id'];
  reportName: string;
  reportFormat: Flexmonster.Report = {};
  isAdmin = false;

  loading: boolean;

  canExport = false;
  exportTypes = ['CSV', 'Excel', 'PDF', 'Image', 'HTML', 'Print'];
  selectedExportType = 'CSV';

  startAt = new Date();
  endAt = new Date();
  startDate = null;
  endDate = null;

  lastLoaded = TimeZone.getFormatedTimezoneFormated();

  timeOffsetReports = ['paymentsgroup', 'kycverificationtimesgroup', 'kyccheckcustomersgroup', 'contractaggregatedgroup'];


  constructor(private ref: ChangeDetectorRef,
              private reportSchemaService: ReportSchemaService,
              private permissionResolver: PermissionResolver,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this.loadReport();

    this.isAdmin = this.permissionResolver.isTenantAdmin() || this.permissionResolver.isSuperUser();

    this.startAt.setHours(0);
    this.startAt.setMinutes(0);
    this.startAt.setSeconds(0);

    this.endAt.setHours(23);
    this.endAt.setMinutes(59);
    this.endAt.setSeconds(0);
  }

  formatFromDateObjectToString(dateObj) {
    if (Object.prototype.toString.call(dateObj) === '[object Date]') {
      return dateObj.getFullYear() + "-" + ("0"+(dateObj.getMonth()+1)).slice(-2) + "-" + ("0" + dateObj.getDate()).slice(-2) + " " + ("0" + dateObj.getHours()).slice(-2) + ":" + ("0" + dateObj.getMinutes()).slice(-2);
    }
    return dateObj;
  }

  changeStartAt() {
    this.startAt = this.startDate;
  }
  changeEndAt() {
    this.endAt = this.endDate;
  }

  loadReport() {
    this.loading = true;

    this.reportSchemaService.getReportSchemaWrapper(this.reportSchemaId).subscribe((response) => {
      this.loading = false;

      this.reportFormat = JSON.parse(response['reportStructure']);
      this.canExport = response['canExport'];
      this.reportName = response['name'];

      let groupName = this.reportFormat.dataSource.filename;

      this.reportFormat.dataSource.filename = `${this.reportSchemaService.getBaseUrl()}report/${this.reportFormat.dataSource.filename}`;

      if (this.reportFormat.dataSource.filename.indexOf('?') === -1) {
        this.reportFormat.dataSource.filename += `?tenantId=${localStorage.getItem('tenant')}`;
      }
      let timeOffset = moment().utcOffset();
      console.log("utc offset for user " + timeOffset);
      if (this.timeOffsetReports.indexOf(groupName) > -1) {
        this.reportFormat.dataSource.filename += `&timeOffset=${timeOffset}`;
      }

      if (this.startDate || this.endDate) {
        if (this.startDate) {
          let dateTimeFormattedStart = this.formatFromDateObjectToString(this.startDate);
          this.reportFormat.dataSource.filename += `&dateFrom=${dateTimeFormattedStart}`;
        }

        if (this.endDate) {
          let dateTimeFormattedEnd = this.formatFromDateObjectToString(this.endDate);
          this.reportFormat.dataSource.filename += `&dateTo=${dateTimeFormattedEnd}`;
        }
      } else {
        if (response.defaultDateFilter) {
          this.endDate = moment().add(1, 'days').format('YYYY-MM-DD');

          switch (response.defaultDateFilter) {
            case 'Last day':
              this.startDate = moment().format('YYYY-MM-DD');
              break;
            case 'Last 7 days':
              this.startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
              break;
            case 'Last 30 days':
              this.startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
              break;
            case 'Last 90 days':
              this.startDate = moment().subtract(90, 'days').format('YYYY-MM-DD');
              break;
            case 'Last 6 months':
              this.startDate = moment().subtract(6, 'months').format('YYYY-MM-DD');
              break;
            case 'Last year':
              this.startDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
              break;
          }

          this.reportFormat.dataSource.filename += this.startDate ? `&dateFrom=${this.startDate}` : '';
          this.reportFormat.dataSource.filename += `&dateTo=${this.endDate}`;
        }
      }

      this.reportFormat.dataSource.requestHeaders = {
        'Authorization': JSON.parse(localStorage.getItem('authorization')).authorization
      };

      this.flexmonster.pivot.flexmonster.setReport(this.reportFormat);

      this.ref.detectChanges();
    },
      error => {
      this.loading = false;
      });
  }

  exportHandler() {
    if (this.selectedExportType === 'Print') {
      this.flexmonster.pivot.flexmonster.toolbar.printHandler();
    } else {
      this.flexmonster.pivot.flexmonster.exportTo(this.selectedExportType, {filename: this.reportName});
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
