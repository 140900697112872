// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.m-40{
  margin: 40px 40px 0 40px;
}

.form_group_modal input{
  width: 115%;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0Usd0JBQXdCO0FBQzFCOztBQUVBO0VBQ0UsV0FBVztBQUNiIiwiZmlsZSI6ImFwcC5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLm0tNDB7XG4gIG1hcmdpbjogNDBweCA0MHB4IDAgNDBweDtcbn1cblxuLmZvcm1fZ3JvdXBfbW9kYWwgaW5wdXR7XG4gIHdpZHRoOiAxMTUlO1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/app.component.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,WAAW;AACb;;AAEA,gYAAgY","sourcesContent":[".m-40{\n  margin: 40px 40px 0 40px;\n}\n\n.form_group_modal input{\n  width: 115%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
