import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';

import {HttpClient} from '@angular/common/http';


@Injectable()
export class CoreIncentivemanagementService extends BaseService {

  constructor(_http: HttpClient, url: string) {
    super(_http, `coreserviceincentivemanagement`, url);
  }

}

