import * as nav from './nav.reducer';

import * as auth from './core-auth/auth/auth.reducer';
import * as user from './core-identity/user/user.reducer';
import * as role from './core-identity/role/role.reducer';

import * as customer from './core-crm/customer/customer.reducer';
import * as customerStatus from './core-crm/customer-status/customer-status.reducer';
import * as identificationType from './core-crm/identification-type/identification-type.reducer';
import * as escalation from './core-crm/escalation/escalation.reducer';

import * as tenant from './core-masterdata/tenant/tenant.reducer';
import * as tenantCustomField from './core-masterdata/tenant-custom-field/tenant-custom-field.reducer';
import * as product from './core-masterdata/product/product.reducer';
import * as productWarranty from './core-masterdata/product-warranty/product-warranty.reducer';
import * as productWarrantyTypes from './core-masterdata/product-warranty-types/product-warranty-types.reducer';
import * as tenantProductWarranty from './core-masterdata/tenant-product-warranty/tenant-product-warranty.reducer';
import * as currency from './core-masterdata/currency/currency.reducer';
import * as country from './core-masterdata/country/country.reducer';
import * as timezone from './core-masterdata/timezone/timezone.reducer';
import * as language from './core-masterdata/language/language.reducer';
import * as module from './core-masterdata/module/module.reducer';
import * as moduleAction from './core-masterdata/module-action/module-action.reducer';
import * as adminZone from './core-masterdata/admin-zone/admin-zone.reducer';
import * as tenantType from './core-masterdata/tenant-type/tenant-type.reducer';
import * as customRegion from './core-masterdata/custom-region/custom-region.reducer';
import * as productAttribute from './core-masterdata/product-attribute/product-attribute.reducer';
import * as productType from './core-masterdata/product-type/product-type.reducer';
import * as productCatalogue from './core-masterdata/product-catalogue/product-catalogue.reducer';
import * as timeInterval from './core-masterdata/time-interval/time-interval.reducer';
import * as smsProvider from './core-messaging/sms-provider/sms-provider.reducer';
import * as smsTemplate from './core-masterdata/sms-template/sms-template.reducer';

import * as rate from './core-accounting/rate/rate.reducer';
import * as accessoryRate from './core-accounting/accessory-rate/accessory-rate.reducer';
import * as contract from './core-accounting/contract/contract.reducer';
import * as rateFinancedDefinition from './core-accounting/rate-financed-definition/rate-financed-definition.reducer';
import * as manualPayment from './core-accounting/manual-payment/manual-payment.reducer';
import * as wallet from './core-accounting/wallet/wallet.reducer';
import * as rateValidationQualification
  from './core-accounting/rate-validation-qualification/rate-validation-qualification.reducer';

import * as stockingPoint from './core-inventory/stocking-point/stocking-point.reducer';
import * as consignment from './core-inventory/consignment/consignment.reducer';
import * as consignmentProduct from './core-inventory/consignment-product/consignment-product.reducer';
import * as stockingPointInventoryProduct
  from './core-inventory/stocking-point/stocking-point-inventory-product.reducer';
import * as consignmentProductInventoryProduct
  from './core-inventory/consignment-product/consignment-product-inventory-product.reducer';
import * as consignmentProductAcceptReceiveStatus
  from './core-inventory/consignment-product-accept-receive-status/consignment-product-accept-receive-status.reducer';
import * as shippingCompany from './core-inventory/shipping-company/shipping-company.reducer';
import * as salesStaffDefinition from './core-fieldforce/sales-staff-definition/sales-staff-definition.reducer';
import * as salesStaff from './core-fieldforce/sales-staff/sales-staff.reducer';
import * as commission from './core-fieldforce/commission/commission.reducer';
import * as commissionReport from './core-fieldforce/commission-report/commission-report.reducer';

import * as ticket from './core-aftersales/ticket/ticket.reducer';
import * as repairCentre from './core-aftersales/repaircentre/repaircentre.reducer';
import * as repairCentreType from './core-aftersales/repaircentre/repaircentretype.reducer';
import * as repairCentreState from './core-aftersales/repaircentre/repaircentrestate.reducer';
import * as inventoryProduct from './core-inventory/inventory-product/inventory-product.reducer';
import * as testFlow from './core-aftersales/test-flow/test-flow.reducer';
import * as workordertestFlow from './core-aftersales/workorder-test-flow/workorder-test-flow.reducer';

import * as ticketCreationFields from './core-masterdata/ticket-creation-fields/ticket-creation-fields.reducer';
import * as workOrder from './core-aftersales/work-order/workorder.reducer';

import * as permissionChannelGroup from './core-identity/permission-channel-group/permission-channel-group.reducer';

import * as tenantInventoryProductLocationStatus
  from './core-inventory/inventory-product/tenant-inventory-product-location-status.reducer';
import * as productPartPrice from './core-masterdata/product-part-price/product-part-price.reducer';
import * as repairCentreTechnician from './core-aftersales/repaircentre/repair-centre-tecnhnician.reducer';
import * as faultWarrantyDisposition from './core-masterdata/fault-action-setup/fault-warranty-disposition.reducer';
import * as fault from './core-aftersales/fault/fault.reducer';
import * as part from './core-aftersales/part/part.reducer';
import * as workOrderStatus from './core-aftersales/work-order/workorderstatus.reducer';
import * as workOrderRepair from './core-aftersales/work-order/workorderrepair.reducer';
import * as oowRepairCondition from './core-masterdata/oow-repair-conditions/oow-repair-condition.reducer';
import * as tenantOOwRepairCondition from './core-masterdata/oow-repair-conditions/tenant-oow-repair-condition.reducer';

import * as reportSchema from './core-reporting/report-schema/report-schema.reducer';
import * as workOrderL3 from './core-aftersales/work-order/workorderl3.reducer';
import * as delinquencyDefinition from './core-accounting/delinquency-definition/delinquency-definition';
import * as paymentCategorization from './core-accounting/payment-categorization/payment-categorization.reducer';
import * as tenantHoliday from './core-masterdata/tenant-calendar/tenant-holiday.reducers';
import * as vatConfiguration from './core-accounting/vat-configuration/vat-configuration.reducer';
import * as softLaunchProject from './core-soft-launch/project/soft-launch-project.reducer';
import * as stockingPointUser from './core-inventory/stocking-point-user/stocking-point-user.reducers';
import * as eligibilityDefinition from './core-accounting/eligibility-definition/eligibility-definition';
import * as page from './core-knowledge/page/page.reducer';
import * as route from './core-fieldforce/route/route.reducer';

export {
  nav,
  auth,
  user,
  role,
  rate,
  customer,
  tenant,
  product,
  productWarranty,
  productWarrantyTypes,
  tenantProductWarranty,
  currency,
  country,
  timezone,
  module,
  language,
  moduleAction,
  adminZone,
  tenantType,
  tenantCustomField,
  customRegion,
  productAttribute,
  smsProvider,
  productType,
  productCatalogue,
  timeInterval,
  contract,
  stockingPoint,
  customerStatus,
  rateFinancedDefinition,
  identificationType,
  consignment,
  consignmentProduct,
  salesStaffDefinition,
  salesStaff,
  commission,
  commissionReport,
  stockingPointInventoryProduct,
  consignmentProductInventoryProduct,
  consignmentProductAcceptReceiveStatus,
  shippingCompany,
  manualPayment,
  inventoryProduct,
  wallet,
  escalation,
  permissionChannelGroup,
  accessoryRate,
  ticket,
  rateValidationQualification,
  repairCentre,
  repairCentreType,
  repairCentreState,
  testFlow,
  ticketCreationFields,
  tenantInventoryProductLocationStatus,
  workOrder,
  productPartPrice,
  repairCentreTechnician,
  workordertestFlow,
  faultWarrantyDisposition,
  fault,
  part,
  workOrderStatus,
  oowRepairCondition,
  tenantOOwRepairCondition,
  workOrderRepair,
  reportSchema,
  workOrderL3,
  delinquencyDefinition,
  paymentCategorization,
  tenantHoliday,
  smsTemplate,
  softLaunchProject,
  vatConfiguration,
  stockingPointUser,
  eligibilityDefinition,
  page,
  route
};
