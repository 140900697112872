import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {Contract} from '@appModels/core-accounting/contract/contract';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';

import {CoreAccountingService} from '../core-accounting.service';
import {ContractProductBalanceChangeHistory} from '@appModels/core-accounting/contract-product-balance-change-history/contract-product-balance-change-history';

const ContractProduct = gql`
  query ContractProduct($customerId: Int!) {
    contractProduct(customerId: $customerId)
    {
      id
      contractId
      rate {
        id
        name
      }
      contractStatus {
        id
        name
      }
      tenantProductCatalogue {
        id
        productDisplayName
      }
      creditExpirationDate
      balanceDue
      stockingPoint {
        id
        name
      }
      inventoryProduct {
        id
        accountNumber
      }
      createdAt
    }}`;

@Injectable({providedIn: 'root'})
export class ContractService extends CoreAccountingService {


  constructor(
    private http: HttpClient,
    private apollo: Apollo
  ) {
    super(http, 'contract');
  }

  /**
   *
   *
   * @param {number} customerId
   * @returns {Observable<any>}
   * @memberof ContractService
   */
  getContractProductGraph(customerId: number): Observable<any> {
    return this.apollo.watchQuery({
      query: ContractProduct,
      variables: {
        customerId: customerId
      },
    }).valueChanges;
  }

  /** GET contracts from the server */
  getContracts(): Observable<Contract[]> {
    return this.http.get<Contract[]>(this.base_url + '/', {headers: this.headers}).pipe(
      tap(contracts => this.log(`fetched contracts`)),
      catchError(this.handleError('getContracts', []))
    );
  }

  /** GET TV installation contracts from the server */
  getTVInstallationLocation(id: number): Observable<any> {
    return this.http.get<any>(`${this.base_url}/tvInstallationData/${id}`, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched location`)),
      catchError(this.handleError('getLocation', []))
    );
  }

  /** GET contract by id. Return `undefined` when id not found */
  getContractNo404<Data>(id: number): Observable<Contract> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<Contract[]>(url).pipe(
      map(contracts => contracts[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} contract id=${id}`);
      }),
      catchError(this.handleError<Contract>(`getContract id=${id}`))
    );
  }

  /** GET contract by id. Will 404 if id not found */
  getContract(id: number): Observable<Contract> {
    const url = `${this.base_url}/${id}/`;
    return this.http.get<Contract>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched contract id=${id}`)),
      catchError(this.handleError<Contract>(`getContract id=${id}`))
    );
  }

  /** GET contract by length. Will 404 if id not found */
  getContractLength(id: number): Observable<Contract> {
    const url = `${this.base_url}/${id}/currentLength`;
    return this.http.get<Contract>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched contract id=${id}`)),
      catchError(this.handleError<Contract>(`getContract id=${id}`))
    );
  }

  /** GET activity history contract by id. Will 404 if id not found */
  getActivityHistory(id: number, query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const url = `${this.base_url}/activityHistory/${id}/`;
    return this.http.get<any>(url, {params, headers: this.headers}).pipe(
      tap(_ => this.log(`fetched activity history log id=${id}`)),
      catchError(this.handleError(`getActivityHistoryLog id=${id}`))
    );
  }


  /** GET contract payment plan by id. Will 404 if id not found */
  getContractPayment(id: number): Observable<any> {
    const url = `${this.base_url}/paymentplan/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched contract id=${id}`)),
      catchError(this.handleError<any>(`getContract id=${id}`))
    );
  }

  /** GET contract payment profile by id. Will 404 if id not found */
  getPaymentProfile(id: number): Observable<any> {
    const url = `${this.base_url}/paymentprofile/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched contract id=${id}`)),
      catchError(this.handleError<any>(`getContract id=${id}`))
    );
  }

  /** GET contract product components by id. Will 404 if id not found */
  getProductComponents(id: number): Observable<any> {
    const url = `${this.base_url}/components/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched contract id=${id}`)),
      catchError(this.handleError<any>(`getContract id=${id}`))
    );
  }

  /** GET contract product components by id. Will 404 if id not found */
  getProductAccessories(id: number): Observable<any> {
    const url = `${this.base_url}/accessories/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched contract id=${id}`)),
      catchError(this.handleError<any>(`getContract id=${id}`))
    );
  }

  /** GET contract tokens by id. Will 404 if id not found */
  getContractTokens(id: number): Observable<any> {
    const url = `${this.base_url}/accountNumbers/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched tokens id=${id}`)),
      catchError(this.handleError<any>(`getContractTokens id=${id}`))
    );
  }

  /** GET contract mmp history by id. Will 404 if id not found */
  getMMPHistory(id: number): Observable<any> {
    const url = `${this.base_url}/${id}/minimummonthlypayment/history`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched mmp payment history id=${id}`)),
      catchError(this.handleError<any>(`getContract id=${id}`))
    );
  }

   /** GET resubmission count by id. Will 404 if id not found */
   getSubmissionCount(id: number): Observable<Contract> {
    const url = `${this.base_url}/verification/${id}/resubmissionCount`;
    return this.http.get<Contract>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched contract id=${id}`)),
      catchError(this.handleError<Contract>(`getContract id=${id}`))
    );
  }

  getCalculatedRate(id: number, financed: boolean, rateId: number): Observable<any> {
    const url = `${this.base_url}/${id}/expectedchanges/isfinanced/${financed}/rate/${rateId}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched calculated rate id=${id}`)),
      catchError(this.handleError<any>(`getCalculatedRate id=${id}`))
    );
  }

  /* GET contracts whose name contains search term */
  searchContracts(term: string): Observable<Contract[]> {
    if (!term.trim()) {
      // if not search term, return empty contract array.
      return of([]);
    }
    return this.http.get<Contract[]>(`api/contracts/?name=${term}`).pipe(
      tap(_ => this.log(`found contracts matching "${term}"`)),
      catchError(this.handleError<Contract[]>('searchContracts', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new contract to the server */
  addContract(contract: any): Observable<Contract> {
    return this.http.post<Contract>(this.base_url + '/', contract, {headers: this.headers}).pipe(
      tap((contract: Contract) => this.log(`added contract w/ id=${contract.id}`)),
      catchError(this.handleError<Contract>('addContract'))
    );
  }

  /** POST: add bulk buyout price to the server */
  addBalanceBulk(data: any): Observable<any> {
    this.setHeader(true);
    return this.http.post<any>(this.base_url + '/balance/bulk/tenant/' + localStorage.getItem('tenant'), data, {headers: this.headers}).pipe(
      tap((contract: Contract) => this.log(`added contract w/ id=${contract.id}`)),
      catchError(this.handleError<any>('addContract'))
    );
  }

  /** POST: add a new contract to the server */
  addContractAdditionalInfo(contract: any): Observable<{}> {
    return this.http.post(`${this.base_url}/additionalInfo/${contract.id}`, contract.value, {headers: this.headers}).pipe(
      tap((res) => this.log(`added contract  info `)),
      catchError(this.handleError('addContract'))
    );
  }

  /** POST: activate contract verification to the current user */
  activateContractVerification(id: number): Observable<{}> {
    return this.http.post(`${this.base_url}/verification/${id}/activate`, {}, {headers: this.headers}).pipe(
      tap((res) => this.log(`activated contract verification info `)),
      catchError(this.handleError('activateContractVerification'))
    );
  }

  /** POST: assign contract verification to the current user */
  assignContractVerification(id: number): Observable<{}> {
    return this.http.post(`${this.base_url}/verification/${id}/assign`, {}, {headers: this.headers}).pipe(
      tap((res) => this.log(`assign contract verification info `)),
      catchError(this.handleError('assignContractVerification'))
    );
  }

  /** POST: assign contract verification to the current user */
  unassignContractVerification(id: number, force: boolean): Observable<{}> {
    return this.http.post(`${this.base_url}/verification/${id}/unassign?force=${force}`, {}, {headers: this.headers}).pipe(
      tap((res) => this.log(`assign contract verification info `)),
      catchError(this.handleError('assignContractVerification'))
    );
  }

   /** POST: blacklist contract verification to the current user */
   blacklistContractVerification(id: number, data: any): Observable<{}> 
   {
    return this.http.post(`${this.base_url}/verification/${id}/blacklist`, {additionalComments: data.additionalComments, additionalKycInfo: data.additionalKycInfo}, {headers: this.headers}).pipe(
      tap((res) => this.log(`blacklist contract verification info `)),
      catchError(this.handleError('blacklistContractVerification'))
    );
  }

  requestedCallback(id: number, timestamp: number){
    return this.http.post(`${this.base_url}/verification/${id}/callBackTimestamp/${timestamp}/requestCallBack`,  {headers: this.headers}).pipe(
      tap((res) => this.log(`reject contract verification info `)),
      catchError(this.handleError('rejectContractVerification'))
    );
  }
  /** POST: reject contract verification to the current user */
  rejectContractVerification(id: number, data: any): Observable<{}> {
    return this.http.post(`${this.base_url}/verification/${id}/reject`, {additionalComments: data.additionalComments, additionalKycInfo: data.additionalKycInfo}, {headers: this.headers}).pipe(
      tap((res) => this.log(`reject contract verification info `)),
      catchError(this.handleError('rejectContractVerification'))
    );
  }

  /** POST: return contract verification to the current user */
  returnContractVerification(id: number, data: any): Observable<{}> {
    return this.http.post(`${this.base_url}/verification/${id}/return`, {additionalComments: data.additionalComments, additionalKycInfo: data.additionalKycInfo}, {headers: this.headers}).pipe(
      tap((res) => this.log(`return contract verification info `)),
      catchError(this.handleError('returnContractVerification'))
    );
  }

  /** POST: Modify contract withheld amount to the server */
  updateWithheldAmount(id: number, data: any): Observable<{}> {
    return this.http.post(`${this.base_url}/${id}/minimummonthlypayment/`, data, {headers: this.headers}).pipe(
      tap((res) => this.log(`added contract  info `)),
      catchError(this.handleError('addContract'))
    );
  }

  /** POST: Modify contract withheld percentage to the server */
  updateWithheldPercentage(id: number, percentage: number): Observable<{}> {
    return this.http.post(`${this.base_url}/${id}/percentagewithheld/${percentage}`, {headers: this.headers}).pipe(
      tap((res) => this.log(`added contract  info `)),
      catchError(this.handleError('addContract'))
    );
  }

   /** POST: Modify contract ptp date to the server */
   updatePTPDate(id: number, data: any): Observable<{}> {
    return this.http.post(`${this.base_url}/${id}/ptpdate`, data, {headers: this.headers}).pipe(
      tap((res) => this.log(`added contract PTP info `)),
      catchError(this.handleError('addContract PTP'))
    );
  }

  /** DELETE: delete the contract from the server */
  deleteContract(contract: Contract | number): Observable<Contract> {
    const id = typeof contract === 'number' ? contract : contract.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<Contract>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted contract id=${id}`)),
      catchError(this.handleError<Contract>('deleteContract'))
    );
  }

  /** PUT: update the contract on the server */
  updateContract(contract: Contract): Observable<any> {
    return this.http.put(`${this.base_url}/${contract.id}`, contract, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated contract id=${contract.id}`)),
      catchError(this.handleError<any>('updateContract'))
    );
  }

  /** PUT: calculate payment */
  calculatePayment(payload: any): Observable<any> {
    return this.http.put(`${this.base_url}/calculatepayments/`, payload, {headers: this.headers}).pipe(
      tap(res => console.log(res, 'res')),
      catchError(this.handleError<any>('updateContract'))
    );
  }

  /** POST adjust balance **/
  adjustBalance(contractProductId: number, balance: ContractProductBalanceChangeHistory): Observable<any> {
    return this.http.post(`${this.base_url}/contractproduct/${contractProductId}/balance`, balance, {headers: this.headers}).pipe(
      tap(res => console.log(res, 'res')),
      catchError(this.handleError<any>('adjustBalance'))
    );
  }

  /** GET adjust balance **/
  getBalanceAdjustments(contractProductId: number): Observable<any> {
    return this.http.get(`${this.base_url}/contractproduct/${contractProductId}/balancehistory`, {headers: this.headers}).pipe(
      tap(res => console.log(res, 'res')),
      catchError(this.handleError<any>('getBalanceAdjustments'))
    );
  }

  /** GET remaining days **/
  getRemainingDays(accountNumber: string, contractProductId?: number): Observable<any> {
    let params = new HttpParams();
    if (accountNumber) {
      params = params.append('accountNumber', accountNumber);
    }
    if (contractProductId) {
      params = params.append('contractProductId', contractProductId.toString());
    }

    return this.http.get(`${this.base_url}/remainingdays`, {
      params: params,
      headers: this.headers
    }).pipe(
      tap(res => console.log(res, 'res')),
      catchError(this.handleError<any>('getRemainingDays'))
    );
  }

  /** GET customer warranty extensions from the server */
  getCustomerWarrantyExtensions(contractProductId: number, query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    return this.http.get<any>(this.base_url + '/warrantyExtensions/' + contractProductId, {
      params: params,
      headers: this.headers
    }).pipe(
      tap(countries => this.log(`fetched warranty extensions`)),
      catchError(this.handleError('getCustomerWarrantyExtensions', []))
    );
  }

  /** GET customer warranty extensions from the server */
  getCustomerWarrantyExtensionsByAccountNumber(accountNumber: string): Observable<any> {
    return this.http.get<any>(this.base_url + '/warrantyExtensions/byAccountNumber/' + accountNumber, {headers: this.headers
    }).pipe(
      tap(countries => this.log(`fetched warranty extensions`)),
      catchError(this.handleError('getCustomerWarrantyExtensionsByAccountNumber', []))
    );
  }

  /** GET contract product statuses from the server */
  getContractProductStatuses(): Observable<any> {
    return this.http.get<any>(this.base_url + 'productstatus/', {headers: this.headers
    }).pipe(
      tap(countries => this.log(`fetched contract product statuses`)),
      catchError(this.handleError('getContractProductStatuses', []))
    );
  }

  /** PATCH: Modify contract ptp date to the server */
  updateAdditionalStockingPoint(productId: number, stockingPointId: number): Observable<{}> {
    return this.http.patch(`${this.base_url}/product/${productId}/stockingpoint/${stockingPointId}`, {headers: this.headers}).pipe(
      tap((res) => this.log(`added additional stocking point `)),
      catchError(this.handleError('updateAdditionalStockingPoint'))
    );
  }

  getContractAmountsForEscalation(id: number): Observable<Contract> {
    const url = `${this.base_url}/${id}/paidAndRemaining`;
    return this.http.get<Contract>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched contract id=${id}`)),
      catchError(this.handleError<Contract>(`getContract id=${id}`))
    );
  }

  sendContractUrlViaSms(contractProductId: number){
    return this.http.get(`${this.base_url.replace('contract','customer')}/contract/generate-sms/${contractProductId}`);
  }

  generateDigitalContract(contractProductId:number){
    return this.http.get(`${this.base_url.replace('contract','customer')}/contract/${contractProductId}`, {
      headers: this.headers 
    });
  }

}
