import {Injectable} from '@angular/core';
import {CoreAftersalesService} from '@appServices/core-aftersales/core-aftersales.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from "rxjs";
import {RepairCentreType} from '@appModels/core-aftersales/repaircentretype';
import {catchError, tap} from 'rxjs/operators';
import { CoreFieldforceService } from '@appServices/core-fieldforce/core-fieldforce.service';

@Injectable({
  providedIn: 'root'
})
export class TseSearchService extends CoreFieldforceService {

  constructor(private http: HttpClient) {
    super(http, '');
  }


  getTses(regionId: number): Observable<any> {
    const url = `${this.base_url}tseSearch/countryAdministrativeZoneId/${regionId}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
        tap(data => 
            this.log(`getAllReferalls`)
        ),
        catchError(this.handleError<any>(`Failed to get TSEs`, 500))
    );
  }
}


