import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { CoreCrmService } from "../core-crm.service";
import { catchError, tap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class UpsellProductService extends CoreCrmService {
  constructor(private http: HttpClient) {
    super(http, "upsellengine");
  }

  getProductMetrics(query) {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach((val) => {
        if (
          query[val] !== null &&
          query[val] !== undefined &&
          query[val] !== ""
        ) {
          params = params.append(val, query[val]);
        }
      });
    }
    const tenantId = localStorage.getItem("tenant");
    const url = `${this.base_url}/eligibleproducts/tenant/${tenantId}`;
    return this.http
      .get<any>(url, { headers: this.headers, params: params })
      .pipe(
        tap((getProductMetrics) => this.log(`getProductMetrics`)),
        catchError(this.handleError("getProductMetrics", []))
      );
  }

  changeUpsellStatus(id: number, query) {
    const url = `${this.base_url}/eligibleproducts/${id}/status`;
    return this.http
      .get<any>(url, { headers: this.headers, params: query })
      .pipe(
        tap((getProductMetrics) => this.log(`getProductMetrics`)),
        catchError(this.handleError("getProductMetrics", []))
      );
  }

  saveProductMetrics(query) {
    const tenantId = localStorage.getItem("tenant");
    const obj = { tenantId: tenantId, accessoryGlobalProductId: null };
    const completeQuery = { ...query, ...obj };

    const url = `${this.base_url}/eligibleproducts`;

    return this.http
      .post<any>(url, completeQuery, { headers: this.headers })
      .pipe(
        tap((saveProductMetrics) => this.log(`saveProductMetrics`)),
        catchError(this.handleError("saveProductMetrics", []))
      );
  }

  getCustomerUpsellList(query) {
    let params = new HttpParams();
    if (query !== undefined) {
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    const tenantId = localStorage.getItem("tenant");
    const url = `${this.base_url}/customers/tenant/${tenantId}`;
    return this.http.get<any>(url, { params, headers: this.headers }).pipe(
      tap((getProductMetrics) => this.log(`getCustomerUpsellList`)),
      catchError(this.handleError("getCustomerUpsellList", []))
    );
  }

  getUpsellListByStatus(id: number) {
    const tenantId = localStorage.getItem("tenant");
    const url = `${this.base_url}/customers/tenant/${tenantId}`;
    let params = new HttpParams().set("upsellStatus", id);

    return this.http.get<any>(url, { params, headers: this.headers }).pipe(
      tap((getProductMetrics) => this.log(`getCustomerUpsellList`)),
      catchError(this.handleError("getCustomerUpsellList", []))
    );
  }

  assignUpsell(data) {
    const url = `${this.base_url}/customers/process`;
    return this.http.post<any>(url, data, { headers: this.headers }).pipe(
      tap((saveProductMetrics) => this.log(`assignUpsellrow`)),
      catchError(this.handleError("assignUpsellrow", 500))
    );
  }

  assign(id:number) {
    const url = `${this.base_url}/customers/assign/${id}`;
    return this.http.get<any>(url , { headers: this.headers }).pipe(
      tap((saveProductMetrics) => this.log(`assignUpsellrow`)),
      catchError(this.handleError("assignUpsellrow", 500))
    );
  }

  unaassign(id:number) {
    const url = `${this.base_url}/customers/unassign/${id}`;
    return this.http.get<any>(url , { headers: this.headers }).pipe(
      tap((saveProductMetrics) => this.log(`assignUpsellrow`)),
      catchError(this.handleError("assignUpsellrow", 500))
    );
  }

  reassignToTSE(customerId, salesStaff){
    const url = `${this.base_url}/customers/${customerId}/assign/manual/${salesStaff}`;
    return this.http.post<any>(url, { headers: this.headers }).pipe(
      tap((saveProductMetrics) => this.log(`reassigned`)),
      catchError(this.handleError("reassigned", 500))
    );

  }
}
