import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {pick} from 'lodash';

import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {User} from '@appModels/core-identity/user/user';

import {CoreAuthService} from '../core-auth.service';


const pickProps = data => pick(data, [
  'username',
  'firstName',
  'lastName',
  'password',
  'confirmPassword',
  'email',
  'enabled',
  'phoneNumber',
  'accountNonExpired',
  'accountNonLocked',
  'credentialsNonExpired',
  'credentialNonExpired',
  'enabled',
  'reasonDisabledEnabled'
]);

@Injectable({providedIn: 'root'})
export class UserService extends CoreAuthService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, 'user');
  }

  /** GET users from the server */
  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.base_url + 's/', {headers: this.headers}).pipe(
      tap(users => this.log(`fetched users`)),
      catchError(this.handleError('getUsers', []))
    );
  }

  /** GET users from the server paged */
  getUsersPaged(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      // {page:1, size:10, sort: '' }
      Object.keys(query).forEach(val => {
        if (query[val] !== null && query[val] !== undefined && query[val] !== '') {
          params = params.append(val, query[val]);
        }
      });
    }

    return this.http.get<any>(this.base_url + 's/paged', {params: params, headers: this.headers}).pipe(
      tap(users => this.log(`fetched users`)),
      catchError(this.handleError('getUsers', []))
    );
  }

  /** GET user by id. Return `undefined` when id not found */
  getUserNo404<Data>(id: number): Observable<User> {
    const url = `${this.base_url}/?id=${id}`;
    return this.http.get<User[]>(url).pipe(
      map(users => users[0]), // returns a {0|1} element array
      tap(h => {
        const outcome = h ? `fetched` : `did not find`;
        this.log(`${outcome} user id=${id}`);
      }),
      catchError(this.handleError<User>(`getUser id=${id}`))
    );
  }

  /** GET user by id. Will 404 if id not found */
  getUser(id: number): Observable<User> {
    const url = `${this.base_url}/id/${id}`;
    return this.http.get<User>(url).pipe(
      tap(_ => this.log(`fetched user id=${id}`)),
      catchError(this.handleError<User>(`getUser id=${id}`))
    );
  }

  getUserByUsername(usernameEmailMobile: string): Observable<User> {
    const url = `${this.base_url}/username/${usernameEmailMobile}`;
    return this.http.get<User>(url, {headers: new HttpHeaders({headers: this.headers})}).pipe(
      tap(_ => this.log(`fetched user username=${usernameEmailMobile}`)),
      catchError(this.handleError<User>(`getUser username=${usernameEmailMobile}`))
    );
  }

  emailActivation(): Observable<any> {
    return this.http.post(this.base_url + '/emailactivation/send', {})
  }

  verifyEmailActivation(data): Observable<any> {
    return this.http.post(this.base_url + '/emailactivation', data)
  }

  /* GET users whose name contains search term */
  searchUsers(term: string): Observable<User[]> {
    if (!term.trim()) {
      // if not search term, return empty user array.
      return of([]);
    }
    return this.http.get<User[]>(`api/users/?name=${term}`).pipe(
      tap(_ => this.log(`found users matching "${term}"`)),
      catchError(this.handleError<User[]>('searchUsers', []))
    );
  }

  /* GET users whose name contains search term */
  searchUsersByEmail(email: string): Observable<User[]> {
    const tenantId = localStorage.getItem('tenant');

    let params = new HttpParams();
    params = params.append('email', email);

    return this.http.get<User[]>(this.base_url + '/search/tenant/' + tenantId, {
      params: params,
      headers: this.headers
    }).pipe(
      tap(countries => this.log(`fetched searched users`)),
      catchError(this.handleError('searchUsersByEmail', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new user to the server */
  addUser(user, isFieldForce = false): Observable<User> {
    let path = window.location.href;
    let tenantId = localStorage.getItem('tenant');
    path = path.replace(window.location.pathname, '/auth/activate-account');
    return this.http.post(this.base_url + `/?frontendURL=${path}&tenantId=${tenantId}&isFieldForceRegistration=${isFieldForce}`, {...user}, {headers: this.headers}).pipe(
      tap((newUser: User) => {
        this.log(`added user w/ id=${newUser.id}`);
      }),
      catchError(this.handleError<User>('addUser'))
    );
  }

  /** POST: send reset password to the server */
  resetPassword(user): Observable<any> {
    return this.http.post<any>(this.base_url + '/resetpassword', user).pipe(
      tap((user: User) => this.log(`email sent w/ id=${user}`)),
      catchError(this.handleError<any>('sendEmail'))
    );
  }

   /** POST: send change password to the server */
   changePassword(data): Observable<any> {
    return this.http.post(this.base_url + '/changepassword', data, {observe: 'response', responseType: 'text'})
  }

  /** POST: send reset password to the server */
  resendCredential(id, url): Observable<any> {
    return this.http.post<any>(`${this.base_url}/resendcredentials/${id}?frontendURL=${url}`, {}).pipe(
      tap((user: any) => this.log(`email sent w/ id=${user}`)),
      catchError(this.handleError<any>('sendEmail'))
    );
  }


  uploadUser(user): Observable<{}> {
    this.setHeader(true);
    let tenantId = localStorage.getItem('tenant');
    let path = window.location.href;
    path = path.replace(window.location.pathname, '/auth/activate-account');
    let frontendURL = `${path}`;
    return this.http.post<{}>(this.base_url + 's/batchupload/tenant/' + tenantId + '/?frontendURL=' + frontendURL, user, {headers: this.headers}).pipe(
      tap((user) => this.log(`uploaded users `)),
      catchError(this.handleError<{}>('uploadUser'))
    );
  }

  uploadUserRole(user): Observable<{}> {
    this.setHeader(true);
    let tenantId = localStorage.getItem('tenant');
    return this.http.post<{}>(this.base_url + 's/batchuploadroles/tenant/' + tenantId, user, {headers: this.headers}).pipe(
      tap((user) => this.log(`uploaded users `)),
      catchError(this.handleError<{}>('uploadUser'))
    );
  }

  /** DELETE: delete the user from the server */
  deleteUser(user: User | number): Observable<User> {
    const id = typeof user === 'number' ? user : user.id;
    const url = `${this.base_url}/${id}`;

    return this.http.delete<User>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted user id=${id}`)),
      catchError(this.handleError<User>('deleteUser'))
    );
  }

  /** PUT: update the user on the server */
  updateUser(user: User): Observable<any> {
    return this.http.put(`${this.base_url}/${user.id}`, pickProps(user), {headers: this.headers}).pipe(
      tap(_ => this.log(`updated user id=${user.id}`)),
      catchError(this.handleError<any>('updateUser'))
    );
  }

  /** PUT: confirm user reset password on the server */
  confirmPassword(newPassword): Observable<any> {
    return this.http.post(`${this.base_url}/confirmresetpassword/resetcode/${newPassword.resetCode}/newpassword`, newPassword).pipe(
      tap(_ => this.log(`updated user`))
    );
  }


  /** PUT: confirm user reset password on the server */
  activateAccount(code): Observable<any> {
    return this.http.put(`${this.base_url}/confirmaccountactivate/activationcode/${code}`, {}).pipe(
      tap(_ => this.log(`Activated`)),
      catchError(this.handleError<any>('Activate account'))
    );
  }

  /** Patch: update the user on the server */
  patchRoleToUser(userId: number, roleId: number): Observable<any> {

    return this.http.patch(`${this.base_url}/${userId}/role/${roleId}`, {}, {headers: this.headers}).pipe(
      tap(_ => this.log(`patched role to user`)),
      catchError(this.handleError<any>('update user'))
    );
  }

  /** Delete: update the user on the server */
  deleteRoleToUser(userId: number, roleId: number): Observable<any> {
    return this.http.delete<any>(`${this.base_url}/${userId}/role/${roleId}`, {headers: this.headers}).pipe(
      tap(_ => this.log(`deleted role to user`)),
      catchError(this.handleError<any>('deleted user'))
    );
  }

  /** GET tenants where user is in */
  getTenantsWhereUserIn(userId: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.base_url}/${userId}/tenants`, {headers: this.headers}).pipe(
      tap(users => this.log(`fetched users`)),
      catchError(this.handleError('getUsers', []))
    );
  }


  uploadProfilePicture(userId, profilePicture): Observable<User> {
    this.setHeader(true);
    return this.http.post<User>(this.base_url + `/${userId}/updateprofilepicture`, profilePicture, {headers: this.headers}).pipe(
      tap((user) => this.log(`uploaded profile picture`)),
      catchError(this.handleError<User>('profile picture'))
    );
  }

  /** Download user photo by id. Will 404 if id not found */
  downloadProfilePhoto(id: number): Observable<any> {
    const url = `${this.base_url}/${id}/downloadprofileimage`;
    return this.http.get(url, {headers: this.headers, responseType: 'blob'}).pipe(
      tap(_ => this.log(`fetched user id=${id}`)),
      catchError(this.handleError<any>(`getUser`, []))
    );
  }

  /** POST sets the currently logged in user as active */
  updateUserActive() {
    return this.http.post(this.base_url + `/active`, {headers: this.headers}).pipe(
      tap(_ => this.log(`updated user active`)),
      catchError(this.handleError<any>('failed to update user active'))
    );
  }

}

