export const upsellEligibityStatus = [
    { "id": 0, "text": "All" },
    { "id": 1, "text": "Pending" },
    { "id": 2, "text": "Call in progress" },
    { "id": 3, "text": "Accepted" },
    { "id": 4, "text": "Rejected" },
    { "id": 5, "text": "Requested call back" },
    { "id": 6, "text": "Assigned" },
    { "id": 7, "text": "Converted" },
    { "id": 8, "text": "Assigned TSE" },
    { "id": 9, "text": "Cancelled" }
  ]
