import { CommonModule } from "@angular/common";
import { ChangeDetectorRef, Component, DestroyRef, Inject, OnInit, ViewEncapsulation, inject, signal } from "@angular/core";
import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog";

import { Subject, of } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { distinctUntilChanged, finalize, switchMap, takeUntil } from "rxjs/operators";

import { ToastrService } from "ngx-toastr";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TranslateModule } from "@ngx-translate/core";
import { MatTableModule } from "@angular/material/table";
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltipModule } from '@angular/material/tooltip';

import { __formatText__ } from "../__utils__/__formatText__";

import { AdminZoneService } from "@appServices/core-masterdata/admin-zone/admin-zone.service";
import { UpsellProductService } from "@appServices/core-crm/upsell/upsell-products.service";
import { CustomerTelesalesService } from "@appServices/core-crm/call/customer-telesales/customer-telesales.service";
import {UpsellDispositionService} from "@appServices/core-crm/upsell/upsell-disposition.service";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import { SharedComponentModule } from "@appContainers/shared-component/shared-component.module";
import { TseSearchService } from "@appServices/core-fieldforce/tse/tse-search.service";

@Component({
  selector: "app-crm-upsell-modal",
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule, MatDialogModule, MatProgressSpinnerModule, MatExpansionModule, MatTableModule, MatRadioModule, PopoverModule, MatFormFieldModule, MatOptionModule, MatSelectModule, MatTooltipModule, SharedComponentModule],
  templateUrl: "./crm-upsell-modal.component.html",
  styleUrl: "./crm-upsell-modal.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class CrmUpsellModalComponent implements OnInit {
  customerTelesalesService = inject(CustomerTelesalesService);
  adminZoneService = inject(AdminZoneService);
  upsellService = inject(UpsellProductService);
  tseService = inject(TseSearchService)

  destroyRef = inject(DestroyRef);
  private destroy$ = new Subject<void>();

  displayedColumns: string[] = ['name', 'deposit', 'unitAmount', 'duration', 'total'];

  telesaleForm: FormGroup;

  isLoading = signal(true);
  readonly panelOpenState = signal(false);
  prefferedCallBack: string = '';
  formatText =  __formatText__;

  selectedProductId: number | null = null;
  selectedCampaignId: number | null = null;
  selectedRate: any;

  selectedOption = 'accept';
  reason: string;
  staffSelected: any;

  salesStaffId = null;
  region = null;

  agentName = JSON.parse(localStorage.getItem("user")).firstName;
  agentEmail = JSON.parse(localStorage.getItem("user")).email;
  upsellModalData: any;
  preventPanelOpen: boolean = false;
  loadingStaff: boolean = true;

  salesAgentQuery: string;
  salesAgentResult: any[] = [];
  filteredRegions = [];
  adminZones = [];
  staff = [];
  checkSalesAgentLoading: boolean = false;
  mandatoryField: boolean = false;
  disableApproved: boolean = false;
  isAssigning: boolean = true;
  credentialsOfSalesStuff: { id: number, name: string };
  isChecked = false;

  constructor(
    private _dialogRef: MatDialogRef<CrmUpsellModalComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private toastr: ToastrService,
    private upsellDispositionService: UpsellDispositionService
  ) { }

  ngOnInit() {
    this.getAdminZones();
    this.upsellService
      .assign(this._data.id)
      .pipe(
        switchMap(a => this.transformProductsDetails(a)),
        finalize(() => this.isLoading.set(false)),
        takeUntil(this.destroy$)
      )
      .subscribe((a) => {
        this.reason = a.reason ?? '';
        if (['PENDING', 'REQUESTED_CALL_BACK', 'ACCEPTED'].indexOf(this._data.upsellStatus) > -1) {
          this.toastr.success('Successfully Assigned Upsell!')
        } else if ((a as any).alreadyAssignedUser != this.agentEmail) {
          this.toastr.error('Call In Progress and Already Assigned: Please move to another customer!')
        }
        this.initializeForm(a);
      });
  }

  private transformProductsDetails(a) {
    return of(a);
  }

  disabledFields = () => (this.upsellModalData.upsellStatus == 'REJECTED' || this.upsellModalData.upsellStatus == 'ASSIGNED'||  this.upsellModalData.upsellStatus == 'CALL_IN_PROGRESS') && this.upsellModalData.alreadyAssignedUser != this.agentEmail;

  private initializeForm(data: any): void {
    this.upsellModalData = data;
    this.reason = this.upsellModalData?.reason || '';
    this.isChecked = this.upsellModalData?.upsellStatus === "ASSIGNED";
  }


  getAdminZones() {
    this.adminZoneService.getAdminZoneByCountry(+localStorage.getItem('countryId')).subscribe(
      adminZone => {
        this.adminZones = adminZone;
      })
  }

  selectResult(agent) {
    this.salesStaffId = agent.id;
    this.salesAgentQuery = agent.name;
    this.telesaleForm.get('salesAgentQuery').setValue(agent.name);
    this.salesAgentResult = [];
  }

  getFilteredRateDetails(cumulativeRateDetails) {

    if (this.selectedRate?.id && cumulativeRateDetails) {
      return cumulativeRateDetails[this.selectedRate.id];
    }
  }

  assign() {
    if (this.selectedCampaignId && this.selectedProductId && this.selectedRate && this.selectedRate.id) {
      const payload = {
        desiredProductId: this.selectedProductId,
        assignedSalesStaffId: this.salesStaffId,
        interested: this.selectedOption == 'accept' ? true : false,
        id: this._data.id,
        reason: this.reason,
        rateId: this.selectedRate.id,
        requestedCallBackTime: this.prefferedCallBack ? this.prefferedCallBack.split("T")[0] : null,
        financed: this.selectedRate.financed,
        campaignId: this.selectedCampaignId,
        disposition: this.selectedDisposition
      };

      if (!this.isProductAndRateSelected(payload.desiredProductId, payload.rateId)) {
        this.toastr.error("Product ID and Rate ID must be selected.");
        return;
      }

      if (this.selectedOption != 'accept' && this.selectedDisposition == null) {
        this.toastr.error("Please select the disposition");
        return;
      }

      this.isLoading.set(true);
      this.upsellService.assignUpsell(payload).subscribe({
        next: (response) => {
          if (response === 500) {
            this.toastr.error("Error occurred");
            return;
          }

          this.handleUpsellResponse(response);
        },
        error: () => {
          this.toastr.error("Error occurred while assigning upsell.");
        },
        complete: () => {
          this.isLoading.set(false);
          this._dialogRef.close();
        }
      });
    }
  }

  private handleUpsellResponse(response: any): void {
    this.isAssigning = false;

    switch (this.selectedOption) {
      case 'accept':
        this.credentialsOfSalesStuff = response;
        this.toastr.success(`Successfully Assigned to ${this.credentialsOfSalesStuff.name}`);
        this.closeDialog();
        break;
      case 'reject':
        this.toastr.success("Successfully Rejected");
        this.closeDialog();
        break;

      default:
        this.toastr.success("Callback Logged Successfully!");
        this.closeDialog();
    }
  }


  searchRoles(search) {
    this.filteredRegions = this.adminZones.filter(x => x.name.toLowerCase().indexOf(search.toLowerCase()) != -1);
  }
  closeDialog(){
    setTimeout(() => {
      this._dialogRef.close();
    }, 1000);
  }

  private isProductAndRateSelected(productId, rateId): boolean {
    return !!productId && !!rateId;
  }

  onSubCountyChange($event) {
    const data = $event.target.value;

    this.tseService.getTses(data)
        .pipe(distinctUntilChanged())
        .subscribe((tses) => {
          this.staff = tses;
          this.loadingStaff= false;
        })
  }

  areSelectedIdsValid = () => this.selectedCampaignId != null && this.selectedProductId != null && this.selectedRate?.id != null;

  selectRate(rate: any, productId: number, campaignId: number): void {
    this.selectedRate = rate;
    this.selectedProductId = productId;
    this.selectedCampaignId = campaignId;
  }

  checkUpsellStatus()  {
    return this.upsellModalData.upsellStatus == 'REJECTED' || this.upsellModalData.upsellStatus == 'ASSIGNED';
  }

  exitModal() {
    // if (this.upsellModalData.alreadyAssignedUser) {
    //   this._dialogRef.close();
    //   return;
    // }

    this.isLoading.set(true);
    this.upsellService.unaassign(this._data.id)
      .pipe(
        finalize(() => this.isLoading.set(false)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: () => {
          this.toastr.success('Successfully unassigned upsell!');
          this._dialogRef.close();
        },
        error: (err) => {
          this.toastr.error('Failed to unassign upsell.', 'Error');
          console.error('Unassign Error:', err);
        }
      });
  }

  dispositions = [];
  selectedDisposition = null;
  customerResponseChange(event:any) {
    this.selectedDisposition = null;
    if (event.value != 'accept') {
      this.upsellDispositionService.getDispositionsForResponse(event.value).subscribe(x => {
        this.dispositions = x;
      })
    } else {
      this.dispositions = [];
    }
  }

  reassign()
  {
    this.upsellService.reassignToTSE(this._data.id, this.staffSelected )
        .subscribe((res) => {
          if (res === 500) {
            this.toastr.error("Error occurred");
          } else {
            this.toastr.success(`Successfully Reassigned To ${res.name}`);
            this.closeDialog();
          }
        })
  }
}
