import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';


import {CoreFieldforceService} from '../core-fieldforce.service';
import {SalesStaff} from "@appModels/core-fieldforce/sales-staff/sales-staff";


@Injectable({providedIn: 'root'})
export class TenantSalesStaffService extends CoreFieldforceService {

  constructor(
    private http: HttpClient,
  ) {
    super(http, '');
  }

  getTenantSalesStaffs(id: number): Observable<any> {

    const url = `${this.base_url}salesstaff/salesStaffs?tenantId=${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched tenantSaless id=${id}`)),
      catchError(this.handleError(`getTenantSales id=${id}`))
    );
  }

  getSalesStaff(id: number): Observable<any> {

    const url = `${this.base_url}salesstaff/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched sales staff id=${id}`)),
      catchError(this.handleError(`sales staff id=${id}`))
    );
  }

  /* GET sales staff definitions whose name contains search term */
  searchSalesStaff(term: string): Observable<SalesStaff[]> {
    if (!term.trim()) {
      // if not search term, return empty stockingPoint array.
      return of([]);
    }
    return this.http.get<SalesStaff[]>(`${this.base_url}salesstaff/searchSalesStaff?searchString=${term}&tenantId=${localStorage.getItem('tenant')}`).pipe(
      tap(_ => this.log(`found salesStaff matching "${term}"`)),
      catchError(this.handleError<SalesStaff[]>('salesStaff', []))
    );
  }

  /* GET sales staff definitions whose name contains search term */
  searchSalesAgent(term: string): Observable<SalesStaff[]> {
    if (!term.trim()) {
      // if not search term, return empty stockingPoint array.
      return of([]);
    }
    return this.http.get<SalesStaff[]>(`${this.base_url}salesstaff/searchSalesAgent?searchString=${term}&tenantId=${localStorage.getItem('tenant')}`).pipe(
      tap(_ => this.log(`found salesAgent matching "${term}"`)),
      catchError(this.handleError<SalesStaff[]>('salesStaff', []))
    );
  }

  getPossibleLineManagers(id: number): Observable<any> {

    const url = `${this.base_url}getPossibleLineManagers/salesStaffDefinition/${id}`;
    return this.http.get(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched tenantSaless id=${id}`)),
      catchError(this.handleError(`getTenantSales id=${id}`))
    );
  }

  getSalesStaffEditHistory(id: number): Observable<any> {
    const url = `${this.base_url}salesStaff/edits/history/${id}`;
    return this.http.get<any>(url, {headers: this.headers}).pipe(
      tap(_ => this.log(`fetched salesStaffEditHistory id=${id}`)),
      catchError(this.handleError<any>(`getSalesStaffEditHistory id=${id}`))
    );
  }


  /** POST: add a new sales staff definition to the server */
  addSalesStaff(salesStaff: SalesStaff): Observable<SalesStaff> {
    salesStaff.tenantId = Number(localStorage.getItem('tenant'));
    console.log("SSSS " + localStorage.getItem('tenant'));
    return this.http.post<SalesStaff>(this.base_url + 'salesstaff', salesStaff, {headers: this.headers}).pipe(
      //tap((salesStaffDefinition: SalesStaff) => this.log(`added salesStaffDefinition w/ id=${salesStaffDefinition.id}`)),
      catchError(this.handleError<SalesStaff>('addSalesStaff'))
    );
  }

}
