import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({providedIn: 'root'})
export class PermissionResolver {

  /**
   * default permission channel
   */
  private DEFAULT_CHANNEL = 'ATLAS_WEB';

  private TENANT_ADMIN_STRING = 'TENANT_ADMIN';

  private GLOBAL_SERVICE_MANAGER = 'Global Service Manager';

  /**
   *
   */
  private MODULE_NAME = null;

  /**
   * Permissions data
   */
  private permissions = {};

  private formattedPermissions = [];

  private SUPER_ROLE = false;

  private modules = []

  /**
   * Check if user name
   */
  constructor() {

    this.getTenantRoles().forEach(role => {
      if ('SUPER_ROLE' === role.name) {
        // this.SUPER_ROLE = true;
      }
    })

  }

  /**
   *
   * @param moduleName
   */
  public setModuleName(moduleName) {
    this.MODULE_NAME = moduleName;
    return this;
  }

  /**
   * Set Custom Channel Name
   * @param ChannelName
   */
  public setChannel(ChannelName) {
    this.DEFAULT_CHANNEL = ChannelName;
    return this;
  }

  /**
   * Check for a permission per module
   * @param value
   */
  public can(value) {
    if (this.SUPER_ROLE) {
      return true;
    }
    let permissionsResult = this.getModulePermissions();
    return permissionsResult !== undefined && permissionsResult !== null && permissionsResult.includes(value);
  }

  /**
   * Search for a permission globally
   * @param permission
   */
  public have(permission) {
    if (Array.isArray(permission)) {
      return permission.reduce((previousPermission, nextPermission) => {
        return previousPermission && this.privateHave(nextPermission);
      });
    }
    return this.privateHave(permission);
  }

  /**
   *
   * @param moduleName
   */
  public getModulePermissions() {
    this.loadPermissions();
    return this.permissions[this.MODULE_NAME];
  }

  /**
   * return User Modules
   */
  public getModules() {
    return this.getChannelPermission().length > 0 ? this.getChannelPermission() : [];
  }

  public isTenantAdmin() {
    let isAdmin = false;
    this.getTenantRoles().forEach(role => {
      if (role.name && role.name.includes(this.TENANT_ADMIN_STRING)) {
        isAdmin = true
      }
    })
    return isAdmin;
  }

  public isGlobalServiceManager() {
    let isAdmin = false;
    this.getTenantRoles().forEach(role => {
      if (role.name && role.name.startsWith(this.GLOBAL_SERVICE_MANAGER)) {
        isAdmin = true;
      }
    });
    return isAdmin;
  }

  public isSuperUser() {
    return this.SUPER_ROLE;
  }

  /**
   *
   *
   * @private
   * @memberof PermissionResolver
   */
  private privateHave(permission) {
   this.loadPermissions();
   let have = false;
  //  return this.formattedPermissions.includes(permission);
    Object.keys(this.permissions).forEach(val => {
      if (this.permissions[val].includes(permission)) {
        have = true;
      }
    });
    return have;
  }

  /**
   *
   *
   * @private
   * @param {*} val
   * @returns
   * @memberof PermissionResolver
   */
  private optional(val) {
    if (val === null || val === undefined) {
      return {};
    }
    return val;
  }

  /**
   * get Selected Tenant ID
   */
  private getTenantId() {
    return parseInt(localStorage.getItem('tenant'));
  }

  /**
   * get selected tenant detail
   */
  private getTenant() {
    const tenants = JSON.parse(localStorage.getItem('tenants'));
    return tenants.find(o => o.id === this.getTenantId());
  }

  /**
   * get selected tenant roles
   */
  private getTenantRoles() {
    return this.getTenant().roles_.length > 0 ? this.getTenant().roles_ : [];
  }

  /**
   * get selected role by channel name
   * @param name
   */
  private getTenantRolesChannel() {
    let defaultChannels = [];
    if (this.getTenantRoles().length > 0) {
      this.getTenantRoles().forEach(role => {
        let defaultChannel = role.channels_.find(o => o.name === this.DEFAULT_CHANNEL);
        if (defaultChannel) {
          defaultChannels.push(defaultChannel)
        }
      })
    }
    return defaultChannels;
  }

  /**
   * get channel permission
   */
  private getChannelPermission() {
    if (this.modules.length > 0 ) {
      return this.modules;
    }

    let modules = [];
    this.getTenantRolesChannel().forEach(defaultChannel => {
      if (defaultChannel.permissions_ !== undefined) {
        modules = [...modules, ...defaultChannel.permissions_[0].modules_];
      }
    });
    // get only unique items
    // this.modules =  Array.from(new Map(modules.map<any>(item => [item['id'], item])).values());
    const grouped = _.groupBy(modules, (item) => item.id);
    this.modules = Object.values(grouped).map((values: any) => {
      let permission = null;
      values.forEach(v => {
        if (permission === null) {
          permission = {
            id: v.id,
            name: v.name,
            modulePermissions_: v.modulePermissions_
          };
        } else {
          permission = {
            ...permission,
            modulePermissions_: [ ...permission.modulePermissions_, ...v.modulePermissions_]
          };
        }
      });
      return permission;
    });
    return this.modules;
  }

  /**
   * Load permissions into the permission attribute
   */
  private loadPermissions() {
    if(Object.keys(this.permissions).length > 0) return;

    this.getModules().forEach(val => {
      // this.formattedPermissions = [...this.formattedPermissions, ...val.modulePermissions_.map(v => v.name)];
      if (this.permissions[val.name] === undefined) {
        this.permissions[val.name] = val.modulePermissions_.map(v => v.name);
      } else {
        this.permissions[val.name] = [...this.permissions[val.name], ...val.modulePermissions_.map(v => v.name)];
      }
    })
  }

}

