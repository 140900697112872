import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import {ToastrService} from "ngx-toastr";

import {TicketCustomFieldService} from "@appServices/core-ticketing/ticket-custom-field-service";
import {CustomField} from "@appModels/ticketing/custom-field";

@Component({
  moduleId: module.id,
  selector: 'base-system-field-directive',
  templateUrl: './base-system-field-directive.html',
  animations: [
    trigger('toggleAnimation', [
      transition(':enter', [style({ opacity: 0, transform: 'scale(0.95)' }), animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))]),
      transition(':leave', [animate('75ms', style({ opacity: 0, transform: 'scale(0.95)' }))]),
    ]),
  ],
  styleUrls: ['../../tailwind.css']
})
export class BaseSystemFieldDirective {
  constructor(
    public ticketCustomFieldService: TicketCustomFieldService,
    public toastrService: ToastrService
  ) {}

  customField: CustomField = {
    id:0,
    name:""
  }

  ticketId:number = null;

  @Input() set setTicketId(ticketId) {
    this.ticketId = ticketId;
  }

  @Input() set setCustomField(field) {
    this.customField = field;
  }


  public saveCustomFieldValue() {

    this.ticketCustomFieldService.updateCustomField(this.customField, this.ticketId).subscribe(x => {
        this.toastrService.success("Updated " + this.customField.name);
    })
  }



}

