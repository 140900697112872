import {ActionReducerMap, MetaReducer} from '@ngrx/store';
import {routerReducer, RouterReducerState} from '@ngrx/router-store';

import {storeFreeze} from 'ngrx-store-freeze';

import {environment} from '../../environments/environment';

import * as fromReducers from './reducers';

import {NavEffects} from '@appStore/effects/nav.effects';
import {RouterEffects} from '@appStore/effects/router.effects';

import {AuthEffects} from '@appStore/effects/core-auth/auth/auth.effects';
import {UserEffects} from '@appStore/effects/core-identity/user/user.effects';
import {RoleEffects} from '@appStore/effects/core-identity/role/role.effects';
import {CustomerEffects} from '@appStore/effects/core-crm/customer/customer.effects';
import {TenantEffects} from './effects/core-masterdata/tenant/tenant.effects';
import {ProductEffects} from './effects/core-masterdata/product/product.effects';

import {RouterStateUrl} from '@appStore/router';
import {CountryEffects} from './effects/core-masterdata/country/country.effects';
import {CurrencyEffects} from './effects/core-masterdata/currency/currency.effects';
import {ModuleActionEffects} from './effects/core-masterdata/module-action/module-action.effects';
import {TimezoneEffects} from './effects/core-masterdata/timezone/timezone.effects';
import {LanguageEffects} from './effects/core-masterdata/language/language.effects';
import {AdminZoneEffects} from './effects/core-masterdata/admin-zone/admin-zone.effects';
import {TenantTypeEffects} from './effects/core-masterdata/tenant-type/tenant-type.effects';
import {CustomRegionEffects} from './effects/core-masterdata/custom-region/custom-region.effects';
import {ProductAttributeEffects} from './effects/core-masterdata/product-attribute/product-attribute.effects';
import {RateEffects} from './effects/core-accounting/rate/rate.effects';
import {RateFinancedDefinitionEffects} from './effects/core-accounting/rate-financed-definition/rate-financed-definition.effects';
import {TimeIntervalEffects} from './effects/core-masterdata/time-interval/time-interval.effects';
import {ProductTypeEffects} from './effects/core-masterdata/product-type/product-type.effects';
import {ProductCatalogueEffects} from './effects/core-masterdata/product-catalogue/product-catalogue.effects';

import {ContractEffects} from './effects/core-accounting/contract/contract.effects';
import {StockingPointEffects} from './effects/core-inventory/stocking-point/stocking-point.effects';
import {ConsignmentEffects} from './effects/core-inventory/consignment/consignment.effects';
import {PurchasingOrderEffects} from './effects/core-inventory/purchasing-order/purchasing-order.effects';
import {ConsignmentProductEffects} from './effects/core-inventory/consignment-product/consignment-product.effects';
import {CustomerStatusEffects} from './effects/core-crm/customer-status/customer-status.effects';
import {IdentificationTypeEffects} from './effects/core-crm/identification-type/identification-type.effects';
import {ModuleEffects} from './effects/core-masterdata/module/module.effects';
import {TenantCustomFieldEffects} from './effects/core-masterdata/tenant-custom-field/tenant-custom-field.effects';
import {SmsProviderEffects} from './effects/core-messaging/sms-provider/sms-provider.effects';
import {StockingPointInventoryProductsEffects} from './effects/core-inventory/stocking-point/stocking-point-inventory-products.effects';
import {ConsignmentProductAcceptReceiveStatusEffects} from './effects/core-inventory/consignment-product-accept-receive-status/consignment-product-accept-receive-status.effects';
import {ShippingCompanyEffects} from '@appStore/effects/core-inventory/shipping-company/shipping-company.effects';
import {SalesStaffDefinitionEffects} from './effects/core-fieldforce/sales-staff-definition/sales-staff-definition.effects';
import {SalesStaffEffects} from './effects/core-fieldforce/sales-staff/sales-staff.effects';
import {CommissionEffects} from './effects/core-fieldforce/commission/commission.effects';
import {CommissionReportEffects} from './effects/core-fieldforce/commission-report/commission-report.effects';
import {TicketEffects} from './effects/core-aftersales/ticket/ticket.effects';

import {ManualPaymentEffects} from './effects/core-accounting/manual-payment/manual-payment.effects';
import {AccessoryRateEffects} from './effects/core-accounting/accessory-rate/accessory-rate.effects';

import {InventoryProductEffects} from '@appStore/effects/core-inventory/inventory-product/inventory-product.effects';
import {WalletEffects} from './effects/core-accounting/wallet/wallet.effects';
import {EscalationEffects} from './effects/core-crm/escalation/escalation.effects';

import {PermissionChannelGroupEffects} from './effects/core-identity/permission-channel-group/permission-channel-group.effects';
import {RateValidationQualificationEffects} from './effects/core-accounting/rate-validation-qualification/rate-validation-qualification.effects';
import {TenantInventoryProductLocationStatusEffects} from '@appStore/effects/core-inventory/inventory-product/tenant-inventory-product-location-status.effects';

import {ProductWarrantyEffects} from './effects/core-masterdata/product-warranty/product-warranty.effects';
import {TenantProductWarrantyEffects} from './effects/core-masterdata/tenant-product-warranty/tenant-product-warranty';
import {ProductWarrantyTypesEffects} from './effects/core-masterdata/product-warranty-types/product-warranty-types.effects';
import {RepairCentreEffects} from './effects/core-aftersales/repaircentre/repaircentre.effects';
import {RepairCentreTypeEffects} from './effects/core-aftersales/repaircentre/repaircentretype.effects';
import {RepairCentreStateEffects} from './effects/core-aftersales/repaircentre/repaircentrestate.effects';
import {TestFlowEffects} from './effects/core-aftersales/test-flow/test-flow.effects';
import {TicketCreationFieldsEffects} from './effects/core-masterdata/ticket-creation-fields/ticket-creation-fields.effects';
import {WorkOrderEffects} from './effects/core-aftersales/work-order/workorder.effects';
import {ProductPartPriceEffects} from './effects/core-masterdata/product-part-price/product-part-price.effects';
import {RepairCentreTechnicianEffects} from './effects/core-aftersales/repaircentre/repair-centre-technician.effects';
import {FaultWarrantDispositionEffects} from './effects/core-masterdata/fault-action-setup/fault-warrant-disposition.effects';
import {WorkorderTestFlowEffects} from './effects/core-aftersales/workorder-test-flow/workorder-test-flow.effects';
import {FaultEffects} from './effects/core-aftersales/fault/fault.effects';
import {PartEffects} from './effects/core-masterdata/part/part.effects';
import {WorkorderStateEffects} from './effects/core-aftersales/work-order/workorder-state-effects.service';
import {WorkOrderRepairEffects} from './effects/core-aftersales/work-order/workorderrepair.effects';
import {TenantOowRepairConditionsEffects} from './effects/core-masterdata/oow-repair-conditions/tenant-oow-repair-conditions.effects';

import {ReportSchemaEffects} from '@appStore/effects/core-reporting/report-schema/report-schema.effects';
import {SmsTemplateEffects} from './effects/core-masterdata/sms-template/sms-template.effects';
import {Workorderl3Effects} from './effects/core-aftersales/work-order/workorderl3.effects';
import {DelinquencyDefinitionEffects} from './effects/core-accounting/delinquency-definition/delinquency-definition.effects';
import {PaymentCategorizationEffects} from './effects/core-accounting/payment-categorization/payment-categorization.effects';
import {OowRepairConditionsEffects} from './effects/core-masterdata/oow-repair-conditions/oow-repair-conditions.effects';
import {TenantHolidayEffects} from './effects/core-masterdata/tenant-calendar/tenant-holiday.effects';
import {SoftLaunchProjectEffects} from './effects/core-soft-launch/project/soft-launch-project.effects';
import {VatConfigurationEffects} from './effects/core-accounting/vat-configuration/vat-configuration.effects';
import {StockingPointUserEffects} from "./effects/core-inventory/stocking-point-user/stocking-point-user.effects";
import { EligibilityDefinitionEffects } from './effects/core-accounting/eligibility-definition/eligibility-definition.effects';
import { PageEffects } from './effects/core-knowledge/page/page.effects';
import { RouteEffects } from './effects/core-fieldforce/route/route.effects';

export interface State {
  nav: fromReducers.nav.State;
  auth: fromReducers.auth.State;
  user: fromReducers.user.State;
  role: fromReducers.role.State;
  customer: fromReducers.customer.State;
  tenant: fromReducers.tenant.State;
  product: fromReducers.product.State;
  productWarranty: fromReducers.productWarranty.State;
  productWarrantyTypes: fromReducers.productWarrantyTypes.State;
  tenantProductWarranty: fromReducers.tenantProductWarranty.State;
  country: fromReducers.country.State;
  timezone: fromReducers.timezone.State;
  currency: fromReducers.currency.State;
  language: fromReducers.language.State;
  module: fromReducers.module.State;
  moduleAction: fromReducers.moduleAction.State;
  adminZone: fromReducers.adminZone.State;
  tenantType: fromReducers.tenantType.State;
  customRegion: fromReducers.customRegion.State;
  productAttribute: fromReducers.productAttribute.State;
  productType: fromReducers.productType.State;
  productCatalogue: fromReducers.productCatalogue.State;
  rate: fromReducers.rate.State;
  rateFinancedDefinition: fromReducers.rateFinancedDefinition.State;
  rateValidationQualification: fromReducers.rateValidationQualification.State;
  timeInterval: fromReducers.timeInterval.State;
  contract: fromReducers.contract.State;
  stockingPoint: fromReducers.stockingPoint.State;
  salesStaffDefinition: fromReducers.salesStaffDefinition.State;
  salesStaff: fromReducers.salesStaff.State;
  commission: fromReducers.commission.State;
  commissionReport: fromReducers.commissionReport.State;
  consignment: fromReducers.consignment.State;
  consignmentProduct: fromReducers.consignmentProduct.State;
  customerStatus: fromReducers.customerStatus.State;
  tenantCustomField: fromReducers.tenantCustomField.State;
  identificationType: fromReducers.identificationType.State;
  smsProvider: fromReducers.smsProvider.State;
  router: RouterReducerState<RouterStateUrl>;
  stockingPointInventoryProduct: fromReducers.stockingPointInventoryProduct.State;
  consignmentProductInventoryProduct: fromReducers.consignmentProductInventoryProduct.State;
  consignmentProductAcceptReceiveStatus: fromReducers.consignmentProductAcceptReceiveStatus.State;
  shippingCompany: fromReducers.shippingCompany.State;
  manualPayment: fromReducers.manualPayment.State;
  inventoryProduct: fromReducers.inventoryProduct.State;
  wallet: fromReducers.wallet.State;
  escalation: fromReducers.escalation.State;
  permissionChannelGroupEffects: fromReducers.permissionChannelGroup.State;
  accessoryRate: fromReducers.accessoryRate.State;
  ticket: fromReducers.ticket.State;
  repairCentre: fromReducers.repairCentre.State;
  repairCentreType: fromReducers.repairCentreType.State;
  repairCentreState: fromReducers.repairCentreState.State;
  testFlow: fromReducers.testFlow.State;
  ticketCreationFields: fromReducers.ticketCreationFields.State;
  workOrder: fromReducers.workOrder.State;
  tenantInventoryProductLocationStatus: fromReducers.tenantInventoryProductLocationStatus.State;
  productPartPrice: fromReducers.productPartPrice.State;
  repairCentreTechnician: fromReducers.repairCentreTechnician.State;
  workordertestFlow: fromReducers.workordertestFlow.State;
  faultWarrantyDisposition: fromReducers.faultWarrantyDisposition.State;
  fault: fromReducers.fault.State;
  part: fromReducers.part.State;
  workOrderStatus: fromReducers.workOrderStatus.State;
  workOrderRepair: fromReducers.workOrderRepair.State;
  oowRepairCondition: fromReducers.oowRepairCondition.State;
  tenantOOwRepairCondition: fromReducers.tenantOOwRepairCondition.State;
  reportSchema: fromReducers.reportSchema.State;
  workOrderL3: fromReducers.workOrderL3.State;
  smsTemplate: fromReducers.smsTemplate.State;
  delinquencyDefinition: fromReducers.delinquencyDefinition.State;
  paymentCategorization: fromReducers.paymentCategorization.State;
  tenantHoliday: fromReducers.tenantHoliday.State;
  softLaunchProject: fromReducers.softLaunchProject.State;
  vatConfiguration: fromReducers.vatConfiguration.State;
  eligibilityDefinition: fromReducers.eligibilityDefinition.State;
  stockingPointUser: fromReducers.stockingPointUser.State;
  page: fromReducers.page.State;
  route: fromReducers.route.State;
}

export const reducers: ActionReducerMap<State> = {
  nav: fromReducers.nav.reducer,
  auth: fromReducers.auth.reducer,
  user: fromReducers.user.reducer,
  role: fromReducers.role.reducer,
  customer: fromReducers.customer.reducer,
  module: fromReducers.module.reducer,
  moduleAction: fromReducers.moduleAction.reducer,
  productCatalogue: fromReducers.productCatalogue.reducer,
  tenant: fromReducers.tenant.reducer,
  product: fromReducers.product.reducer,
  productWarranty: fromReducers.productWarranty.reducer,
  tenantProductWarranty: fromReducers.tenantProductWarranty.reducer,
  productWarrantyTypes: fromReducers.productWarrantyTypes.reducer,
  productAttribute: fromReducers.productAttribute.reducer,
  productType: fromReducers.productType.reducer,
  country: fromReducers.country.reducer,
  timezone: fromReducers.timezone.reducer,
  currency: fromReducers.currency.reducer,
  language: fromReducers.language.reducer,
  adminZone: fromReducers.adminZone.reducer,
  tenantType: fromReducers.tenantType.reducer,
  customRegion: fromReducers.customRegion.reducer,
  rate: fromReducers.rate.reducer,
  rateFinancedDefinition: fromReducers.rateFinancedDefinition.reducer,
  rateValidationQualification: fromReducers.rateValidationQualification.reducer,
  timeInterval: fromReducers.timeInterval.reducer,
  contract: fromReducers.contract.reducer,
  stockingPoint: fromReducers.stockingPoint.reducer,
  salesStaffDefinition: fromReducers.salesStaffDefinition.reducer,
  salesStaff: fromReducers.salesStaff.reducer,
  commission: fromReducers.commission.reducer,
  commissionReport: fromReducers.commissionReport.reducer,
  consignment: fromReducers.consignment.reducer,
  consignmentProduct: fromReducers.consignmentProduct.reducer,
  smsProvider: fromReducers.smsProvider.reducer,
  tenantCustomField: fromReducers.tenantCustomField.reducer,
  customerStatus: fromReducers.customerStatus.reducer,
  identificationType: fromReducers.identificationType.reducer,
  router: routerReducer,
  stockingPointInventoryProduct: fromReducers.stockingPointInventoryProduct.reducer,
  consignmentProductInventoryProduct: fromReducers.consignmentProductInventoryProduct.reducer,
  consignmentProductAcceptReceiveStatus: fromReducers.consignmentProductAcceptReceiveStatus.reducer,
  shippingCompany: fromReducers.shippingCompany.reducer,
  manualPayment: fromReducers.manualPayment.reducer,
  inventoryProduct: fromReducers.inventoryProduct.reducer,
  wallet: fromReducers.wallet.reducer,
  escalation: fromReducers.escalation.reducer,
  accessoryRate: fromReducers.accessoryRate.reducer,
  permissionChannelGroupEffects: fromReducers.permissionChannelGroup.reducer,
  ticket: fromReducers.ticket.reducer,
  repairCentre: fromReducers.repairCentre.reducer,
  repairCentreType: fromReducers.repairCentreType.reducer,
  repairCentreState: fromReducers.repairCentreState.reducer,
  testFlow: fromReducers.testFlow.reducer,
  ticketCreationFields: fromReducers.ticketCreationFields.reducer,
  workOrder: fromReducers.workOrder.reducer,
  tenantInventoryProductLocationStatus: fromReducers.tenantInventoryProductLocationStatus.reducer,
  productPartPrice: fromReducers.productPartPrice.reducer,
  repairCentreTechnician: fromReducers.repairCentreTechnician.reducer,
  faultWarrantyDisposition: fromReducers.faultWarrantyDisposition.reducer,
  workordertestFlow: fromReducers.workordertestFlow.reducer,
  fault: fromReducers.fault.reducer,
  part: fromReducers.part.reducer,
  workOrderStatus: fromReducers.workOrderStatus.reducer,
  workOrderRepair: fromReducers.workOrderRepair.reducer,
  reportSchema: fromReducers.reportSchema.reducer,
  smsTemplate: fromReducers.smsTemplate.reducer,
  oowRepairCondition: fromReducers.oowRepairCondition.reducer,
  tenantOOwRepairCondition: fromReducers.tenantOOwRepairCondition.reducer,
  workOrderL3: fromReducers.workOrderL3.reducer,
  delinquencyDefinition: fromReducers.delinquencyDefinition.reducer,
  tenantHoliday: fromReducers.tenantHoliday.reducer,
  softLaunchProject: fromReducers.softLaunchProject.reducer,
  paymentCategorization: fromReducers.paymentCategorization.reducer,
  vatConfiguration: fromReducers.vatConfiguration.reducer,
  eligibilityDefinition: fromReducers.eligibilityDefinition.reducer,
  stockingPointUser: fromReducers.stockingPointUser.reducer,
  page: fromReducers.page.reducer,
  route: fromReducers.route.reducer
};

export const effects = [
  NavEffects,
  AuthEffects,
  UserEffects,
  RoleEffects,
  CustomerEffects,
  TenantEffects,
  ProductEffects,
  ProductWarrantyEffects,
  ProductWarrantyTypesEffects,
  TenantProductWarrantyEffects,
  CountryEffects,
  CurrencyEffects,
  ModuleEffects,
  TimezoneEffects,
  LanguageEffects,
  ModuleActionEffects,
  AdminZoneEffects,
  TenantTypeEffects,
  CustomRegionEffects,
  ProductAttributeEffects,
  ProductTypeEffects,
  ProductCatalogueEffects,
  RateEffects,
  RateFinancedDefinitionEffects,
  RouterEffects,
  TimeIntervalEffects,
  ContractEffects,
  StockingPointEffects,
  ConsignmentEffects,
  PurchasingOrderEffects,
  ConsignmentProductEffects,
  CustomerStatusEffects,
  TenantCustomFieldEffects,
  IdentificationTypeEffects,
  SmsProviderEffects,
  StockingPointInventoryProductsEffects,
  ConsignmentProductAcceptReceiveStatusEffects,
  ShippingCompanyEffects,
  SalesStaffDefinitionEffects,
  SalesStaffEffects,
  CommissionEffects,
  CommissionReportEffects,
  ManualPaymentEffects,
  InventoryProductEffects,
  WalletEffects,
  EscalationEffects,
  PermissionChannelGroupEffects,
  AccessoryRateEffects,
  TicketEffects,
  RateValidationQualificationEffects,
  RepairCentreEffects,
  RepairCentreTypeEffects,
  RepairCentreStateEffects,
  TestFlowEffects,
  TicketCreationFieldsEffects,
  RateValidationQualificationEffects,
  TenantInventoryProductLocationStatusEffects,
  WorkOrderEffects,
  ProductPartPriceEffects,
  RepairCentreTechnicianEffects,
  WorkorderTestFlowEffects,
  FaultWarrantDispositionEffects,
  FaultEffects,
  PartEffects,
  WorkorderStateEffects,
  WorkOrderRepairEffects,
  ReportSchemaEffects,
  SmsTemplateEffects,
  OowRepairConditionsEffects,
  TenantOowRepairConditionsEffects,
  Workorderl3Effects,
  DelinquencyDefinitionEffects,
  PaymentCategorizationEffects,
  TenantHolidayEffects,
  VatConfigurationEffects,
  SoftLaunchProjectEffects,
  EligibilityDefinitionEffects,
  StockingPointUserEffects,
  PageEffects,
  RouteEffects
];

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [storeFreeze]
  : [];
