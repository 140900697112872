import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface IAutoCompleteItem {
    id: number
    text: string
}


@Component({
  selector: 'app-inline-loading-spinner',
  templateUrl: './inline-loading-spinner.component.html',
  styleUrls: ['./inline-loading-spinner.component.scss'],
})
export class InlineLoadingSpinnerComponent {
    constructor() { }
}