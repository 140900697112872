import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {Apollo} from 'apollo-angular';

import {Customer} from '@appModels/core-crm/customer/customer';

import {CoreCrmService} from '../core-crm.service';


@Injectable({providedIn: 'root'})
export class DashboardSEPService extends CoreCrmService {

  constructor(
    private http: HttpClient,

  ) {
    super(http, 'crm/sepdashboard');
  }

  // Get sales count
  getSalesCount(dateFrom, dateTo): Observable<any> {
    const url = `${this.base_url}/salescount?dateFrom=${dateFrom}&dateTo=${dateTo}`;
    return this.http.get<any[]>(url, {headers: this.headers}).pipe(
      tap(res => this.log(`res`)),
      catchError(this.handleError('res', []))
    );
  }
  // Get sales product count

  getSalesCountProduct(dateFrom, dateTo): Observable<any> {
    const url = `${this.base_url}/salescountproduct?dateFrom=${dateFrom}&dateTo=${dateTo}`;
    return this.http.get<any[]>(url, {headers: this.headers}).pipe(
      tap(res => this.log(`res`)),
      catchError(this.handleError('res', []))
    );
  }

  // Get Payment Progress of Customers for current user
  getPaymentProgress(): Observable<any> {
    const url = `${this.base_url}/customerpaymentprogress`;
    return this.http.get<any[]>(url, {headers: this.headers}).pipe(
      tap(res => this.log(`res`)),
      catchError(this.handleError('res', []))
    );
  }

    /** GET customers from the server */
    getSalesAgentCustomers(query?): Observable<any> {
      let params = new HttpParams();
      if (query !== undefined) {
        // {page:1, size:10, sort: '' }
        Object.keys(query).forEach(val => {
          params = params.append(val, query[val]);
        });
      }
      return this.http.get<any>(this.base_url + '/customers', {
        params,
        headers: this.headers
      }).pipe(
        tap(customers => this.log(`fetched customers`)),
        catchError(this.handleError('getCustomers', []))
      );
    }

  getTsePortfolioPerformance(userId?): Observable<any> {
    let userParam = userId ? `?userId=${userId}` : '';
    const url = `${this.base_url}/tse/performance${userParam}`;
    return this.http.get<any[]>(url, {headers: this.headers}).pipe(
      tap(res => this.log(`res`)),
      catchError(this.handleError('res', null))
    );
  }

  getPerformanceHistory(salesStaff : number){
    const url = `${this.base_url}/colorHistory/salesStaff/${salesStaff}`;
    return this.http.get<any[]>(url, {headers: this.headers}).pipe(
      tap(res => this.log(`getPerformanceHistory`)),
      catchError(this.handleError('getPerformanceHistory', []))
    );
  }

  getCommissionsList(
    userId: number,
    page: number = 0,
    size: number = 10,
    startDate?: string,
    endDate?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString());

    if (startDate) {
      params = params.set('startDate', startDate);
    }
    if (endDate) {
      params = params.set('endDate', endDate);
    }

    const url = `${this.base_url}/commission/list/${userId}`;
    return this.http.get<any>(url, { params, headers: this.headers }).pipe(
      tap((_) =>
        this.log(
          `fetched commissions list userId=${userId}, page=${page}, size=${size}, startDate=${startDate}, endDate=${endDate}`
        )
      ),
      catchError(this.handleError<any>('getCommissionsList'))
    );
  }

}
