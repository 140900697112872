// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link{
    color: #4a4e53;
}

.router-link-active {
  color: #FF4612!important;
}

.router-link-active > span {
  color: #FF4612!important;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNpZGViYXIuY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtJQUNJLGNBQWM7QUFDbEI7O0FBRUE7RUFDRSx3QkFBd0I7QUFDMUI7O0FBRUE7RUFDRSx3QkFBd0I7QUFDMUIiLCJmaWxlIjoic2lkZWJhci5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmxpbmt7XG4gICAgY29sb3I6ICM0YTRlNTM7XG59XG5cbi5yb3V0ZXItbGluay1hY3RpdmUge1xuICBjb2xvcjogI0ZGNDYxMiFpbXBvcnRhbnQ7XG59XG5cbi5yb3V0ZXItbGluay1hY3RpdmUgPiBzcGFuIHtcbiAgY29sb3I6ICNGRjQ2MTIhaW1wb3J0YW50O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/layout/sidebar/sidebar.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA,ogBAAogB","sourcesContent":[".link{\n    color: #4a4e53;\n}\n\n.router-link-active {\n  color: #FF4612!important;\n}\n\n.router-link-active > span {\n  color: #FF4612!important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
