import { environment } from "environments/environment";

export const featureFlagsFieldForce:Record<string, boolean> = {
  enableHeldPayments: true,
  enableClawback: !environment.production,
  enableClawbackManagement: !environment.production,
  enableSpecialProductVLI: !environment.production,
  enableSalesOverview: !environment.production,
  enableSalesStaffPerformanceHistory:true,
  enableBulkReassignContract:!environment.production,
  enableSecurityDepositRegistry:!environment.production,
  enablePortableCashbackViewOnPayouts:!environment.production,
  enableSepReferral:!environment.production,
  enableSecurityWallet:!environment.production
}
