import {
  Component,
  DestroyRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  effect,
  inject,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { CustomerService } from "@appServices/core-crm/customer/customer.service";
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-cco-crb-checks",
  templateUrl: "./crb-checks.component.html",
  styleUrls: ["./crb-checks.component.scss"],
})
export class CrbChecksComponent implements  OnChanges {
  @Input() uniqueIdentification: any;
  @Input() crbDetailCheck: any;
  @Input() formNotValid: any;
  @Input() statusName: any;

  crbCheckData: any;
  isUpdating = signal(true);
  @Output() crbDetailCheckChange = new EventEmitter<any>();

  destroyRef = inject(DestroyRef);
  customerService = inject(CustomerService);

  constructor() {
    effect(() => {
      console.log(`New value has been updated!}`);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const change = changes["uniqueIdentification"];

    if (change) {
      this.getCRBDetails(change.currentValue);
    }
  }

  getCRBDetails(id) {
    if (id != "") {
      this.customerService
        .getCrbDetails(id)
        .pipe(
          finalize(() => this.isUpdating.set(false)),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((data) => {
          this.crbCheckData = data;
        });
    }
  }

  convertToDateTime(isoString) {
    if(isoString){
      const date = new Date(isoString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } else {
      return '';
    }
  }
}
