// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button_primary {
  height: 42px;
  padding: 10px 25px;
}

#pivot-container {
  padding: 0 40px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1vZHVsZS1yZXBvcnQtbGF5b3V0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsWUFBQTtFQUNBLGtCQUFBO0FBQ0Y7O0FBRUE7RUFDRSxlQUFBO0FBQ0YiLCJmaWxlIjoibW9kdWxlLXJlcG9ydC1sYXlvdXQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYnV0dG9uX3ByaW1hcnkge1xuICBoZWlnaHQ6IDQycHg7XG4gIHBhZGRpbmc6IDEwcHggMjVweDtcbn1cblxuI3Bpdm90LWNvbnRhaW5lciB7XG4gIHBhZGRpbmc6IDAgNDBweDtcbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/containers/shared-component/module-report-layout/module-report-layout.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,eAAA;AACF;AACA,wdAAwd","sourcesContent":[".button_primary {\n  height: 42px;\n  padding: 10px 25px;\n}\n\n#pivot-container {\n  padding: 0 40px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
