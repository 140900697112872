import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { CoreCrmService } from '../core-crm.service';
import { catchError, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UpsellDispositionService extends CoreCrmService {

  constructor(
    private http: HttpClient) {
    super(http, 'upselldisposition');
  }

  getDispositionsForResponse(response : string)
  {
    let params = new HttpParams();
    params = params.append("customerResponse", response);
    const url = `${this.base_url}s`;
    return this.http.get<any>(url, {headers: this.headers, params: params}).pipe(
      tap(getupsellMetrics => this.log(`getUpsellMetrics`)),
      catchError(this.handleError('getUpsellMetrics', []))
    );
  }

}
