import {
  Component,
  Input,
  Output,
  EventEmitter, ChangeDetectorRef
} from '@angular/core';
import Chart from 'chart.js';
import 'rxjs/add/operator/map';
import CustomTooltip from '../custom-chart-tooltip';



@Component({
  selector: 'app-group-bar-chart',
  templateUrl: './group-bar-chart.component.html',
  styleUrls: ['./group-bar-chart.component.scss']
})
export class GroupBarChartComponent {

  @Input() chartName;
  @Input() chartId;
  @Input() stacked;
  @Input() dynamicTime = false;
  @Input() chartType = 'bar';
  @Input() legend = true;
  @Input() groups;
  @Input() groupBy = null;
  @Input() regions;
  @Input() region = null;
  @Output() changeInterval: EventEmitter<any> = new EventEmitter();

  intervalType = 'MONTH';
  timeIntervals = [
    {value: 'DAY', name: 'Day'},
    {value: 'MONTH', name: 'Month'},
    {value: 'QUARTER', name: 'Quarter'}
  ];

  isHidden = true;
  chart = null;

  colorsLoaded = [];
  colors = ['#FF4612', '#666666', '#FFA000', '#D7D7D7',
    '#FFD200', '#ff9b80', '#cc2c00', '#ffd6cc',
    '#660000', '#995c00', '#ccff99'];

  chartOptions = {};

  constructor(private ref: ChangeDetectorRef) {
  }

  initializeNewChart(data) {
    const ctx = document.getElementById(this.chartId);

    this.chartOptions['legend'] = {
      display: this.legend,
      position: 'bottom'
    };

    if (!this.chartOptions['scales']) {
      this.chartOptions['scales'] = {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      };
    }

    if (this.stacked) {
      this.chartOptions['scales']['xAxes'] = [{
        stacked: true,
      }];
      this.chartOptions['scales']['yAxes'] = [{
        stacked: true
      }];
    }

    // overriding labels and datalabels plugins that were used specifically for repayment chart, but somehow
    // are added globally when opening dashboards for the first time from other page (not directly opened or refreshed)
    // if you need to use this in the future, make it optional via Inputs
    this.chartOptions['plugins'] = {
      labels: {
        render: () => {}
      },
      datalabels: {
        display: false
      }
    }

    if (!this.chartOptions['tooltips'])
      this.chartOptions['tooltips'] = CustomTooltip.generateTooltip();

    this.chart = new Chart(ctx, {
      type: this.chartType,
      data: {
        labels: data.labels,
        datasets: data.datasets
      },
      options: this.chartOptions
    });
  }

  updateChart(data) {
    this.chart.config.data = data;
    this.chart.update();
  }

  drawChart(data) {
    if (this.chart) {
      this.updateChart(data);
    } else {
      this.initializeNewChart(data);
    }

    if (this.dynamicTime && this.isHidden) {
      this.isHidden = false;
      this.ref.detectChanges();
    }
  }

  renderChart(data) {
    this.initColorsPalette();
    this.drawChart(this.mapResponseData(data));
  }

  mapResponseData(data) {
    const chartData = {
      labels: [],
      datasets: []
    };

    const keys = Object.keys(data);
    if (keys && keys.length > 0) {
      // fill labels first
      for (const item of data[keys[0]]) {
        chartData.labels.push(item.interval);
      }

      // fill data
      Object.keys(data).forEach(device => {
        const values = [];
        for (const item of data[device]) {
          values.push(item.count);
        }

        // const color = this.getRandomColorHex();
        const color = this.colorsLoaded.shift();

        chartData.datasets.push({
          fill: 'origin',
          label: device,
          data: values,
          backgroundColor: color,
          borderColor: color,
          hoverBackgroundColor: color,
          stack: data[device][0].stack
        });
      });
    }

    return chartData;
  }

  initColorsPalette() {
    this.colorsLoaded = [...this.colors];
    for (let i = 0; i < 100; i++) {
      this.colorsLoaded.push(this.getRandomColorHsl());
    }
  }

  getRandomColorHex() {
    const hex = '0123456789ABCDEF';
    let color = '#1A'; // 10% opacity
    for (let i = 1; i <= 6; i++) {
      color += hex[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getRandomColorHsl() {
    return 'hsl(' + 100 * Math.random() + ',' +
    (25 + 70 * Math.random()) + '%,' +
    (65 + 10 * Math.random()) + '%)';
  }
}
