import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';


@Injectable()
export class CoreJobSchedulerService extends BaseService {

  constructor(public _http: HttpClient, public url: string)
   { super(_http, 'coreservicejobscheduler',url);
   }

}

