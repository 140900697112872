import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {Router} from '@angular/router';

import * as fromSelectors from '@appStore/selectors';
import * as fromReducer from '@appStore/reducers';
import {SubNav} from '@appStore/actions/nav.actions';
import {TenantService} from '@appServices/core-masterdata/tenant/tenant.service';
import {PermissionResolver} from '@appServices/permission-resolver/permission-resolver.service';
import {AuthService} from '@appServices/core-auth/auth/auth.service';
import {GetModules} from '@appStore/actions/core-masterdata/module/module.actions';

import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { INavItem } from 'app/config/nav';

@Component({
  selector: 'ticketing-sidebar-cmp',
  templateUrl: 'ticketing-sidebar.component.html',
  styleUrls: ['./ticketing-sidebar.component.css']
})
export class TicketingSidebarComponent implements OnInit {

  menu$: Observable<[]>;

  routerUrl$: Observable<{}>;

  sidebarActiveTab = 1;

  public version = environment.version;

  @Input()
  isCountryOpen: Boolean = false;

  @Output() isCountryOpenChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  @Input()
  isOpen: Boolean = false;

  @Output() isOpenChange: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  expandedMenu = null;

  user = JSON.parse(localStorage.getItem('user')).email;
  tenants = JSON.parse(localStorage.getItem('tenants'));
  tenantName = localStorage.getItem('tenantName');

  permissions = {
    'can-view-setup-dashboard': false,
    'can-view-rates': false,
    'can-view-financed-rates': false,
    'can-view-product-catalogues': false,
    'can-view-customer-status': false,
    'can-view-identification-type': false
  };

  @Input()
  moduleUrl = null;

  public loading = false;
  currentYear: number;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private store: Store<fromReducer.nav.State>,
    private router: Router,
    private tenantService: TenantService,
    private permissionResolverService: PermissionResolver,
    private authService: AuthService,
    public translate: TranslateService
  ) {
    this.setLang();
  }

  setLang() {
    this.translate.addLangs(['en', 'fr', 'es']);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      this.translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    } else {
      localStorage.setItem('locale', 'en');
      this.translate.setDefaultLang('en');
      this.translate.currentLang = 'en';
    }
  }

  changeLang(language: string) {
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }


  home() {
    this.router.navigateByUrl(`/home`);
  }

  onClick(path) {
    this.router.navigate([path]);
  }

  countrySwitcher() {
    this.isCountryOpen = !this.isCountryOpen;
    // this.isOpen = false
    this.isOpenChange.emit(this.isOpen);
    this.isCountryOpenChange.emit(this.isCountryOpen);
  }

  switchTenant(tenant) {
    this.loading = true;
    localStorage.setItem('tenant', tenant.id);
    localStorage.setItem('tenantName', tenant.name);
    this.authService.saveTenantPref().subscribe(data => {
      console.log(data, 'data');
      this.loading = false;
      window.location.href = '/home';
    });

  }

  clickExpanded(i) {
    this.expandedMenu = this.expandedMenu === i ? null : i;
  }

  changeTab(id) {
    this.sidebarActiveTab = id;
  }

  haveSubMenu(item) {
    return item !== undefined && item.subMenu !== undefined && item.subMenu.length > 0;
  }

  profileSwitcher() {
    this.isOpen = !this.isOpen;
    // this.isCountryOpen = false;
    this.isOpenChange.emit(this.isOpen);
    this.isCountryOpenChange.emit(false);
  }

  logout() {
    localStorage.clear();
    location.reload();
  }

  can(moduleName, action) {
    return this.permissionResolverService.setModuleName(moduleName).can(action);
  }

  have(action) {
    return !action || this.permissionResolverService.have(action);
  }

  isFeatureEnabled(menuItem: INavItem) {
    return typeof menuItem.isFeatureEnabled === 'boolean' ? menuItem.isFeatureEnabled : true
  }

  ngOnInit() {
    this.currentYear = moment().year()
    this.menu$ = this.store.pipe(select(fromSelectors.getNav));
    this.routerUrl$ = this.store.pipe(select(fromSelectors.getRouterUrl));

    // loadFrom LocalStorage
  }

  redirectTo(uri: string) {
    window.location.href = uri;
  }

}
