// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ticket {
    transition: all 0.4s ease-in-out;
}
.ticket:hover {
    box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.15);
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBsYXlncm91bmQtY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO0FBQ0E7SUFDSSxnQ0FBZ0M7QUFDcEM7QUFDQTtJQUNJLDRDQUE0QztBQUNoRCIsImZpbGUiOiJwbGF5Z3JvdW5kLWNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyJcbi50aWNrZXQge1xuICAgIHRyYW5zaXRpb246IGFsbCAwLjRzIGVhc2UtaW4tb3V0O1xufVxuLnRpY2tldDpob3ZlciB7XG4gICAgYm94LXNoYWRvdzogMHB4IDBweCAxcmVtIHJnYmEoMCwgMCwgMCwgMC4xNSk7XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/containers/ticketing/components/component/playground-component.css"],"names":[],"mappings":";AACA;IACI,gCAAgC;AACpC;AACA;IACI,4CAA4C;AAChD;;AAEA,4cAA4c","sourcesContent":["\n.ticket {\n    transition: all 0.4s ease-in-out;\n}\n.ticket:hover {\n    box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.15);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
