import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input, OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as go from 'gojs';
import { DataSyncService, DiagramComponent } from 'gojs-angular';
import {produce} from 'immer';

@Component({
  selector: 'app-library',
  templateUrl: './library-component.html',
  styleUrls: ['./library-component.css'],
})
export class LibraryComponent {



  @Input() set nodeList(list) {
    this.state.diagramNodeData = list;
  }
  @Input() set linkList(list) {
    this.state.diagramLinkData = list;
  }


  jsonString : string = null;
  @Input() set jsonDiagram(str) {
    this.jsonString = str;
  }

  @ViewChild('myDiagram', { static: true })
  public myDiagramComponent: DiagramComponent;
  @Output() rightClick = new EventEmitter();
  public goObject = go;

  @Output() outputJsonEvent = new EventEmitter<any>();


  // Big object that holds app-level state data
  // As of gojs-angular 2.0, immutability is expected and required of state for ease of change detection.
  // Whenever updating state, immutability must be preserved. It is recommended to use immer for this, a small package that makes working with immutable data easy.
  public state = {
    // Diagram state props
    paletteNodeData: [
      { key: 'PaletteNode1', color: 'firebrick' },
      { key: 'PaletteNode2', color: 'blueviolet' }
    ],

    diagramNodeData: [],
    diagramLinkData: [],
    diagramModelData: {},
    skipsDiagramUpdate: false,
  };

  public diagramDivClassName: string = 'myDiagramDiv';
  public paletteDivClassName = 'myPaletteDiv';


  constructor(private cdr: ChangeDetectorRef) {
    // setTimeout(() => {
    //   this.addNewNode(nodeData, listData);
    //   console.log("timedout");
    // }, 5000);

    this.initDiagram = this.initDiagram.bind(this);
  }



  ngAfterViewInit() {
    console.log('ngAfterViewInit library');
    this.cdr.detectChanges(); // IMPORTANT: without this, Angular will throw ExpressionChangedAfterItHasBeenCheckedError (dev mode only)
  }

  // initialize diagram / templates
  public initDiagram(): go.Diagram {



    const $ = go.GraphObject.make;

    let dia = new go.Diagram({

      initialContentAlignment: go.Spot.TopCenter, //center the diagram
      'undoManager.isEnabled': true,
      "clickCreatingTool.archetypeNodeData": { text: "new node" },

      model: new go.GraphLinksModel(
        {
          nodeKeyProperty: 'id',
          linkKeyProperty: 'key' // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
        }
      )
    });

    if (this.jsonString != null && this.jsonString != undefined && this.jsonString.length > 0) {
      console.log("from jsonstring")
      dia.model = go.Model.fromJson(this.jsonString);
      return dia;
    }


    dia.linkTemplate =
      $(go.Link,  // the whole link panel
        {
          curve: go.Link.Bezier,
          adjusting: go.Link.Stretch,
          reshapable: true, relinkableFrom: false, relinkableTo: false,
          toShortLength: 3
        },
        new go.Binding("points").makeTwoWay(),
        new go.Binding("curviness"),
        $(go.Shape,  // the link shape
          { strokeWidth: 1.5 },
          new go.Binding('stroke', 'progress', progress => progress ? "#ffffff" /* green */ : 'black'),
          new go.Binding('strokeWidth', 'progress', progress => progress ? 2.5 : 1.5)),
        $(go.Shape,  // the arrowhead
          { toArrow: "standard", stroke: null },
          new go.Binding('fill', 'progress', progress => progress ? "#52ce60" /* green */ : 'black')),
        $(go.Panel, "Auto",
          $(go.Shape,  // the label background, which becomes transparent around the edges
            {
              fill: $(go.Brush, "Radial",
                { 0: "rgb(255, 255, 255)", 0.7: "rgb(255, 255, 255)", 1: "rgba(255, 255, 255, 0)" }),
              stroke: null
            }),
          $(go.TextBlock, "transition",  // the label text

            {
              textAlign: "center",
              font: "9pt helvetica, arial, sans-serif",
              margin: 4,
              editable: true  // enable in-place editing
            },
            // editing the text automatically updates the model data
            new go.Binding("text").makeTwoWay())
        )
      );

    // some constants that will be reused within templates
    var roundedRectangleParams = {
      parameter1: 2,  // set the rounded corner
      spot1: go.Spot.TopLeft, spot2: go.Spot.BottomRight  // make content go all the way to inside edges of rounded corners
    };

    // define the Node template
    dia.nodeTemplate =
      $(go.Node, 'Auto',
        new go.Binding("location", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),

        $(go.Shape, "RoundedRectangle",
          //roundedRectangleParams,
          new go.Binding('fill', 'color'),
          // new go.Binding("position", "loc", go.Point.parse).makeTwoWay(go.Point.stringify),

          {

            name: "SHAPE",  strokeWidth: 0,
            stroke: null,
            //portId: "",  // this Shape is the Node's port, not the whole Node
            fromLinkable: false, fromLinkableSelfNode: false, fromLinkableDuplicates: false,
            toLinkable: false, toLinkableSelfNode: false, toLinkableDuplicates: false,
            cursor: "pointer"
          }),

        $(go.TextBlock, { margin: 8, editable: true },
          new go.Binding('text').makeTwoWay())
      );

    dia.commandHandler.doKeyDown = function() {
      var e = dia.lastInput;
      if(e.key == "Del") return;
    }

    return dia;
  }

  // When the diagram model changes, update app data to reflect those changes. Be sure to use immer's "produce" function to preserve immutability
  diagramModelChange(changes: go.IncrementalData) {
    console.log('modelchange', changes);
    this.state = produce(this.state, (draft) => {
      // set skipsDiagramUpdate: true since GoJS already has this update
      // this way, we don't log an unneeded transaction in the Diagram's undoManager history
      draft.skipsDiagramUpdate = true;
      draft.diagramNodeData = DataSyncService.syncNodeData(
        changes,
        draft.diagramNodeData,
        this.myDiagramComponent.diagram.model
      );
      draft.diagramLinkData = DataSyncService.syncLinkData(
        changes,
        draft.diagramLinkData,
        this.myDiagramComponent.diagram.model as go.GraphLinksModel
      );
      draft.diagramModelData = DataSyncService.syncModelData(
        changes,
        draft.diagramModelData
      );
    });
  }

  addCustomTemplates(templateList: Array<{ name: string; template: go.Node }>) {
    templateList.forEach((templateData) => {
      this.myDiagramComponent.diagram.nodeTemplateMap.add(
        templateData.name,
        templateData.template
      );
    });
    this.myDiagramComponent.diagram.rebuildParts();
  }

  addNewNode(nodeData, linkData?) {
    this.myDiagramComponent.diagram.startTransaction('make new node');
    console.log("adding new node ");
    console.log(nodeData);
    this.myDiagramComponent.diagram.model.addNodeData(nodeData);
    if (linkData) {
      (this.myDiagramComponent.diagram.model as go.GraphLinksModel).addLinkData(
        linkData
      );
    }
    this.myDiagramComponent.diagram.commitTransaction('make new node');
  }

  addLink(linkData) {
    this.myDiagramComponent.diagram.startTransaction('make new link');
    if (linkData) {
      (this.myDiagramComponent.diagram.model as go.GraphLinksModel).addLinkData(
        linkData
      );
    }
    this.myDiagramComponent.diagram.commitTransaction('make new link');

  }

  removeNode(id:number) {
    let toRemove: go.Node;
    this.myDiagramComponent.diagram.nodes.each(function (x) {
      console.log("node name " + x.key)
      if (x.key == id) {
      toRemove = x;
    }})

    this.myDiagramComponent.diagram.startTransaction();
    this.myDiagramComponent.diagram.remove(toRemove);
    this.myDiagramComponent.diagram.commitTransaction("deleted node");
  }

  removeLink(fromId:number, toId:number) {
    let that = this;
    let  toRemove: go.Link;
    this.myDiagramComponent.diagram.links.each(function(l) { if (l.fromNode.key == fromId && l.toNode.key == toId ){
      // that.myDiagramComponent.diagram.startTransaction("remove link");
      // that.myDiagramComponent.diagram.remove(l);
      // that.myDiagramComponent.diagram.commitTransaction("remove link");
      toRemove = l;
    } });

    that.myDiagramComponent.diagram.startTransaction("remove link");
    that.myDiagramComponent.diagram.remove(toRemove);
    that.myDiagramComponent.diagram.commitTransaction("remove link");

  }

  outputDiagramJson(){

    let diagramModel = this.myDiagramComponent.diagram.model;
    let jsonDiagram = diagramModel.toJson();
    console.log(jsonDiagram);
    this.outputJsonEvent.emit(jsonDiagram);

  }

  public initPalette(): go.Palette {
    const $ = go.GraphObject.make;
    const palette = $(go.Palette);

    // define the Node template
    palette.nodeTemplate =
      $(go.Node, 'Auto',
        $(go.Shape, 'RoundedRectangle', { stroke: null },
          new go.Binding('fill', 'color')
        ),
        $(go.TextBlock, { margin: 8 },
          new go.Binding('text', 'key'))
      );

    palette.model = new go.GraphLinksModel(
      {
        linkKeyProperty: 'key'  // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
      });

    return palette;
  }


}
