import { environment } from "environments/environment";

export const featureFlagsSetup: Record<string, boolean> = {
  enableIsUsedForBilling: !environment.production,
  enableLoyaltyPointsManagement: !environment.production,
  enableProductConfig: !environment.production,
  enableStreakConfig: !environment.production,
  enableLoyaltyDiscount: !environment.production,
  enableCRBProductChecks: true,
  enableCRBProductCheckDelete: true,
  enableLoyaltyTokens: true,
  enableAgedInTrade: true,
  enableLostInTrade: !environment.production,
  enableUpsellOffers: !environment.production,
  enableSmileIdProductCheck: !environment.production,
  enableSkuCheckInPeriod :!environment.production,
  enableFieldForceAttachments: !environment.production
};
