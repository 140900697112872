import {Component, Input, ViewChild} from '@angular/core';
import * as Flexmonster from 'flexmonster';
import {environment} from '../../../environments/environment';
import {FlexmonsterPivot} from 'ngx-flexmonster';

@Component({
  selector: 'app-flexmonster-pivot',
  template: `
    <fm-pivot #pivot
              [licenseKey]="environment.flexmonster_license"
              [toolbar]="true"
              [global]="globalFormat"
              [report]="reportFormat"
              (beforetoolbarcreated)="customizeToolbar($event)"
              (reportcomplete)="onReportComplete()"
              (ready)="onPivotReady($event)">
    </fm-pivot>`
})
export class FlexmonsterPivotComponent {
  @ViewChild('pivot') pivot: FlexmonsterPivot;

  environment = environment;

  globalFormat: Flexmonster.Report = {
    options: {
      grid: {
        showFilter: true,
        dragging: true
      },
      dateTimePattern: `GMT${this.getTimezoneValue()}:dd/MM/yyyy HH:mm:ss`,
      datePattern: `GMT${this.getTimezoneValue()}:dd/MM/yyyy`,
      configuratorButton: false,
      // sorting: false,
      drillThrough: true
    }
  };

  @Input() isAdmin = false;
  @Input() reportFormat: Flexmonster.Report;

  onPivotReady(pivot: Flexmonster.Pivot): void {
    //
  }

  onReportComplete() {
    //
  }

  customizeToolbar(toolbar) {
    // get all tabs
    const tabs = toolbar.getTabs();

    delete tabs[0]; // connect
    delete tabs[1]; // open
    delete tabs[3]; // export

    if (!this.isAdmin) {
      delete tabs[2]; // save (local machine)
      delete tabs[10]; // fields
    }

    const self = this;

    toolbar.getTabs = function () {
      return tabs;
    };
  }

  getTimezoneValue() {
    let timezone = localStorage.getItem('timezone'); // i.e. +01:00
    timezone = timezone.substr(1, timezone.indexOf(':') - 1);

    // const timezoneValue = Number(timezone) + this.getLocalTimeOffset(); // tenant timezone
    const timezoneValue = this.getLocalTimeOffset(); // system timezone

    return `${timezoneValue > 0 ? '+' : ''}${timezoneValue}`;
  }

  getLocalTimeOffset() {
    let offset = new Date().getTimezoneOffset();
    offset = offset / (-60);
    return offset;
  }
}
