import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

interface CacheContent {
  expiry: number;
  value: HttpResponse<any>;
}

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  private cache = new Map<string, CacheContent>();
  private cacheDuration = 120000; // Cache duration in milliseconds (e.g., 120000ms = 2 minutes)

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //if (req.headers.get('skip-cache')) {
      // Clone the request and remove the 'skip-cache' header
      const modifiedReq = req.clone({ headers: req.headers.delete('skip-cache') });
      return next.handle(modifiedReq);
    //}

    // Only cache GET requests
    if (req.method !== 'GET') {
      this.deleteAll();
      return next.handle(req);
    }

    const cachedResponse = this.getFromCache(req.urlWithParams);
    if (cachedResponse) {
      return of(cachedResponse);
    }

    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.addToCache(req.urlWithParams, event);
        }
      }),
      catchError((error) => {
        // Handle the error or rethrow
        // Don't cache it, just pass the error along
        return throwError(error);
      })
    );
  }

  private addToCache(url: string, response: HttpResponse<any>) {
    const expiry = Date.now() + this.cacheDuration;
    this.cache.set(url, { expiry, value: response });
  }

  private deleteCachedItem(url: string) {
    this.cache.delete(url);
  }

  private deleteAll() {
    console.log('Cache cleared');
    this.cache.clear();
  }

  private getFromCache(url: string): HttpResponse<any> | null {
    const cacheContent = this.cache.get(url);

    if (!cacheContent) {
      return null;
    }

    if (cacheContent.expiry < Date.now()) {
      this.cache.delete(url);
      return null;
    }

    return cacheContent.value;
  }
}
