const thousands = /\B(?=(\d{3})+(?!\d))/g;

function generateTooltip(measureUnit = '', formatValueCallback = null) {
  return {
    // Disable the on-canvas tooltip
    enabled: false,
    mode: 'x-axis',

    custom: function(tooltipModel) {
      // Tooltip Element
      let tooltipEl = document.getElementById('chartjs-tooltip');

      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        document.body.appendChild(tooltipEl);
      }

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
      }

      // Set caret Position
      tooltipEl.classList.remove('above', 'below', 'no-transform');
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
      } else {
        tooltipEl.classList.add('no-transform');
      }

      function getBody(bodyItem) {
        return bodyItem.lines;
      }

      // Set Text
      if (tooltipModel.body) {
        let titleLines = tooltipModel.title || [];
        let bodyLines = tooltipModel.body.map(getBody);

        // sort descending
        bodyLines.sort((a, b) => {
          return b[0].split(':').pop() - a[0].split(':').pop();
        });
        // map because colors need original positions index
        let dp = tooltipModel.dataPoints;
        tooltipModel.dataPoints = tooltipModel.dataPoints.map((v, i) => {
          return {i, data: v};
        })
        // sort mapped data
        tooltipModel.dataPoints.sort((a, b) => {
          return b.data.value - a.data.value;
        })


        let innerHtml = '<span style="font-size: 1.2em">' + titleLines[0] + '</span>';
        let totalValue = 0;

        bodyLines.forEach((body, i) => {
          let dataPoints = tooltipModel.dataPoints[i].data;
          let originalIndex = tooltipModel.dataPoints[i].i;

          if (dataPoints.value !== '0') {
            totalValue += Number(dataPoints.value);
            innerHtml += '<br>';
            let colorDot = `<span style="height: 10px; width: 10px; background-color: ${tooltipModel.labelColors[originalIndex].backgroundColor}; display: inline-block; margin: 0 5px;"></span>`;
            innerHtml += colorDot;

            let label = body[0].split(':')[0];

            let formattedLine = body[0].replace(thousands, ',');

            if (formatValueCallback) {
              innerHtml += formatValueCallback(dp, originalIndex, label);
            } else {
              if (measureUnit) {
                innerHtml += formattedLine + ' ' + measureUnit;
              } else {
                innerHtml += formattedLine;
              }
            }
          }
        })

        innerHtml += '<br>Total: ' + totalValue.toString().replace(thousands, ',');

        tooltipEl.innerHTML = innerHtml;
      }

      // `this` will be the overall tooltip
      let position = this._chart.canvas.getBoundingClientRect();

      // Display, position, and set styles for font
      tooltipEl.style.opacity = '0.8';
      tooltipEl.style.background = 'black';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.color = 'white';
      tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
      tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
      tooltipEl.style.fontSize = '0.9em';
      tooltipEl.style.padding = '10px 20px';
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.borderRadius = '5px';
    }
  }
}

export default {
  generateTooltip
}
