import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: "app-map-shared",
  templateUrl: "./map-shared.component.html",
  styleUrls: ["./map-shared.component.css"],
})
export class MapSharedComponent implements OnInit {
  @Input("latitude") latitude;
  @Input("longitude") longitude;
  zoom = 15; // Fixed zoom level
  mapUrl: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.updateMapUrl();
  }

  updateMapUrl(): void {
    const url = `https://maps.google.com/maps?q=${this.latitude},${this.longitude}&z=${this.zoom}&output=embed`;

    this.mapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  updateCoordinates(lat: number, lng: number): void {
    this.latitude = lat;
    this.longitude = lng;
  }
}
