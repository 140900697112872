import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {CoreInventoryService} from '../core-inventory.service';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class PurchasingOrderService extends CoreInventoryService {


  constructor(
    private http: HttpClient,
  ) {
    super(http, '');
  }


  getTransactionData(query?): Observable<any> {
      let params = new HttpParams();
        if (query !== undefined) {
          Object.keys(query).forEach(val => {
            params = params.append(val, query[val]);
          });
        }
        return this.http.get<any>(`${this.base_url}dbschenker/transaction`, {
          params,
          headers: this.headers
        }).pipe(
          tap(_ => this.log(`fetched transaction data`)),
          catchError(this.handleError('get', []))
        );
  }

  authorizeOrder(query?): Observable<any> {
     let params = new HttpParams();
     if (query !== undefined) {
       Object.keys(query).forEach(val => {
         params = params.append(val, query[val]);
       });
     }

     return this.http.patch<any>(`${this.base_url}dbschenker/po/authorize`, null, {
       params: params,
       headers: this.headers
     }).pipe(
       tap(_ => this.log(`Authorize order`)),
       catchError(this.handleError('patch', []))
     );
  }

  createPurchasingOrdersUsingCsv(formData: any): Observable<any> {
        this.setHeader(true);
        return this.http.post<any>(`${this.base_url}dbschenker/po/csv`, formData, {headers: this.headers}).pipe(
          tap(_ => this.log(`create transfer orders Using CSV file`)),
          catchError(this.handleError<any>('createPurchasingOrdersUsingCsv'))
        );
      }
  finalizeOrdersUsingCsv(formData: any): Observable<any> {
      this.setHeader(true);
      return this.http.post<any>(`${this.base_url}dbschenker/po/finalize/bulk`, formData, {headers: this.headers}).pipe(
        tap(_ => this.log(`finalize orders Using CSV file`)),
        catchError(this.handleError<any>('finalizeOrdersUsingCsv'))
      );
   }
   authorizeOrdersUsingCsv(formData: any): Observable<any> {
         this.setHeader(true);
         return this.http.post<any>(`${this.base_url}dbschenker/po/authorize/bulk`, formData, {headers: this.headers}).pipe(
           tap(_ => this.log(`authorize orders Using CSV file`)),
           catchError(this.handleError<any>('authorizeOrdersUsingCsv'))
         );
      }
   finalizeOrder(query?): Observable<any> {
       let params = new HttpParams();
       if (query !== undefined) {
         Object.keys(query).forEach(val => {
           params = params.append(val, query[val]);
         });
       }

       return this.http.patch<any>(`${this.base_url}dbschenker/po/finalize`, null, {
         params: params,
         headers: this.headers
       }).pipe(
         tap(_ => this.log(`Finalize order`)),
         catchError(this.handleError('patch', []))
       );
    }


  getPurchasingOrders(query?): Observable<any> {
    let params = new HttpParams();
    if (query !== undefined) {
      Object.keys(query).forEach(val => {
        params = params.append(val, query[val]);
      });
    }
    return this.http.get<any>(`${this.base_url}dbschenker/po`, {
      params,
      headers: this.headers
    }).pipe(
      tap(_ => this.log(`fetched purchasing orders`)),
      catchError(this.handleError('get', []))
    );
  }




  savePurchasingOrder( data): Observable<any> {
    const url = `${this.base_url}dbschenker/po`;
    return this.http.post(url, data, {headers: this.headers}).pipe(
      tap(_ => this.log(`save purchasing order}`)),
      catchError(err => {
        this.handleError(`savePurchasingOrder`);
        throw err;
      })
    );
  }


}
