import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';

import { HttpClient } from '@angular/common/http';


@Injectable()
export class CoreSmileIdentityService extends BaseService {

  constructor(_http: HttpClient, url: string) {
        super(_http, `coreservicesmileidentity`, url);
   }

}

