import { Injectable } from '@angular/core';
import { CoreJobSchedulerService } from '../core-job-scheduler.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class JobService extends CoreJobSchedulerService {
  constructor(
    private http: HttpClient,
  ) {
    super(http, 'quartzjob/jobscheduler');
  }


  triggerJob(jobName: String): Observable<any> {
    return this.http.get<any>(`${this.base_url}/ondemand?jobName=${jobName}`, { headers: this.headers });
  }

}
