// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticketing-sidebar {
  position:absolute;
  z-index:998;
  height:100vh;
  margin-top:10vh;

}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRpY2tldGluZy1zaWRlYmFyLmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxpQkFBaUI7RUFDakIsV0FBVztFQUNYLFlBQVk7RUFDWixlQUFlOztBQUVqQiIsImZpbGUiOiJ0aWNrZXRpbmctc2lkZWJhci5jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnRpY2tldGluZy1zaWRlYmFyIHtcbiAgcG9zaXRpb246YWJzb2x1dGU7XG4gIHotaW5kZXg6OTk4O1xuICBoZWlnaHQ6MTAwdmg7XG4gIG1hcmdpbi10b3A6MTB2aDtcblxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/layout/ticketing-sidebar/ticketing-sidebar.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,eAAe;;AAEjB;;AAEA,wcAAwc","sourcesContent":[".ticketing-sidebar {\n  position:absolute;\n  z-index:998;\n  height:100vh;\n  margin-top:10vh;\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
